import { FiXCircle } from "react-icons/fi";
import styled from "styled-components";

export const RemoveIcon = styled(FiXCircle)`
  color: red;
  font-size: 20px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
