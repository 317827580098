import { useEffect, useState } from "react";
import { useTypedSelector } from "../../../../../../hooks";
import {
  UseShowGameScoresHookProps,
  UseShowGameScoresHookResponse,
} from "./types";

export const useShowGameScoresHook = ({
  games,
}: UseShowGameScoresHookProps): UseShowGameScoresHookResponse => {
  const [selectedGame, setSelectedGame] = useState(games[0].name || "");
  const { isMultiGameView } = useTypedSelector(
    ({ filterScores }) => filterScores
  );

  useEffect(() => {
    setSelectedGame(games[0].name || "");
  }, [games, setSelectedGame]);

  return { isMultiGameView, selectedGame, setSelectedGame };
};
