import React from "react";
import { DropdownSelectProps } from "./types";
import { useDropdownSelectHook } from "./hook";
import { Dropdown, Wrapper } from "./styles";

export const DropdownSelect = React.memo(
  ({
    label,
    options,
    selectedOption,
    setSelectedOption,
  }: DropdownSelectProps): JSX.Element => {
    const { handleSelectChange } = useDropdownSelectHook({
      options,
      selectedOption,
      setSelectedOption,
    });

    return (
      <Wrapper>
        <label htmlFor="dropdown">{label}</label>
        <Dropdown
          id="dropdown"
          value={selectedOption.value}
          onChange={handleSelectChange}
        >
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Dropdown>
      </Wrapper>
    );
  }
);
