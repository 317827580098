import { VscStarFull } from "react-icons/vsc";
import styled from "styled-components";
import { BREAKPOINT } from "../../styles/constants";

export const LastUpdated = styled.div`
  color: white;
  text-align: center;

  p {
    margin: 0;
  }
`;

export const BackgroundImage = styled.div`
  background-image: url("https://scores.activate.ca/assets/rooms/backgrounds/scan-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Wrapper = styled.div`
  margin: auto;
  padding: 50px 30px;
  min-height: 90vh;
  max-width: 90%;
  @media (max-width: ${BREAKPOINT.tablet}) {
    margin: 0 24px;
  }
`;

export const WrapperPlayerBasicInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.625rem;
  color: white;
`;

export const StarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StarIcon = styled(VscStarFull)`
  margin-right: 12px;
`;

export const LineDivider = styled.hr``;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Information = styled.div`
  width: 78%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  margin-bottom: 36px;
`;

export const SearchWrapper = styled.div`
  margin-top: 100px;
`;
