import { useMemo } from "react";
import { UseLoadingDivHookResponse } from "./types";
import { LOADING_PLACEHOLDERS } from "./constants";

export const useLoadingDivHook = (): UseLoadingDivHookResponse => {
  const randomPlaceholder = useMemo(
    () =>
      LOADING_PLACEHOLDERS[
        Math.floor(Math.random() * LOADING_PLACEHOLDERS.length)
      ],
    []
  );

  return { randomPlaceholder };
};
