import { Badge } from "@/redux/models/BadgesDB";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { addBadge, deleteBadge, fetchAllBadges, updateBadge } from "./thunk";
import { BadgeState } from "./types";

const initialState: BadgeState = {
  badges: [],
  isLoading: false,
  error: null,
};

const badgeSlice = createSlice({
  name: "badges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBadges.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchAllBadges.fulfilled,
        (state, action: PayloadAction<Badge[]>) => {
          state.isLoading = false;
          state.badges = action.payload;
        }
      )
      .addCase(fetchAllBadges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(addBadge.fulfilled, (state, action: PayloadAction<Badge>) => {
        state.badges.push(action.payload);
      })
      .addCase(updateBadge.fulfilled, (state, action: PayloadAction<Badge>) => {
        const index = state.badges.findIndex(
          (b) => b._id === action.payload._id
        );
        if (index !== -1) {
          state.badges[index] = action.payload;
        }
      })
      .addCase(
        deleteBadge.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.badges = state.badges.filter((b) => b._id !== action.payload);
        }
      );
  },
});

export const { reducer: badgesReducer } = badgeSlice;
export const selectBadgesState = (state: RootState) => state.badges;
