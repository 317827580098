import { CSVLink } from "react-csv";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  margin-top: 48px;
  margin-left: 104px;
`;

export const StarsWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ExportDataButton = styled(CSVLink)`
  position: absolute;
  right: 24px;
  background-color: grey;
  color: white;
  font-size: 24px;
  text-decoration: none;
  border: 0.5px solid;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: darkgray;
  }
`;

export const ResetMonthlyScoreButton = styled.button`
  position: absolute;
  top: 80px;
  right: 24px;
  background-color: grey;
  color: white;
  font-size: 24px;
  text-decoration: none;
  border: 0.5px solid;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darkgray;
  }
`;

export const RequestLinkButton = styled.button`
  position: absolute;
  top: 160px; /* Positioning it below the Reset Monthly Score button */
  right: 24px;
  background-color: grey;
  color: white;
  font-size: 24px;
  text-decoration: none;
  border: 0.5px solid;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darkgray;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;
