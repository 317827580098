import styled from "styled-components";

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 8px 0;
  text-align: left;
  font-size: 20px;
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
