import React, { Fragment } from "react";
import { RadioGroupProps } from "./types";

export const RadioGroup = React.memo(
  ({
    value: currentValue,
    onChange,
    name,
    radioItems,
  }: RadioGroupProps): JSX.Element => {
    return (
      <>
        {radioItems.map(({ label, value }, i) => (
          <Fragment key={i}>
            <input
              checked={value === currentValue}
              type="radio"
              id={label}
              name={name}
              value={value}
              onChange={() => onChange(value)}
            />
            <label htmlFor={label}>{label}</label>
          </Fragment>
        ))}
      </>
    );
  }
);
