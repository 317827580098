import { useEffect, useState } from "react";
import { CirclesGrid } from "./components";
import {
  Wrapper,
  LaserMazeContainer,
  Button,
  Title,
  CenteredContainer,
} from "./styles";

const initialWave2 = Array(180)
  .fill(false)
  .map((_, index) => {
    const row = Math.ceil((index + 1) / 18);
    const col = (index + 1) % 18 || 18;
    return row <= 5 || (row > 5 && row <= 10 && [4, 9, 14].includes(col));
  });

const bottomClearWave3 = Array(180)
  .fill(false)
  .map((_, index) => {
    const row = Math.ceil((index + 1) / 18);
    return row <= 5;
  });

const initialWave4 = Array(180)
  .fill(false)
  .map((_, index) => {
    const row = Math.ceil((index + 1) / 18);
    const col = (index + 1) % 18 || 18;
    return (
      row <= 5 || (row > 5 && row <= 10 && [7, 8, 9, 10, 11, 12].includes(col))
    );
  });

export const LaserMaze = (): JSX.Element => {
  const [currentWave, setCurrentWave] = useState(1);

  const [dotStates, setDotStates] = useState<boolean[][]>([
    Array(180)
      .fill(false)
      .map((_, index) => {
        const row = Math.ceil((index + 1) / 18);
        const col = (index + 1) % 18 || 18;
        return row <= 5 || (row === 10 && [4, 9, 14].includes(col));
      }),
    initialWave2,
    initialWave2,
    initialWave4,
    Array(180).fill(false),
  ]);

  useEffect(() => {
    if (currentWave === 2) {
      const intervalId = setInterval(() => {
        setDotStates((prev) =>
          prev.map((row, i) =>
            i === 1
              ? row.map((isLit, index) =>
                  index <= 18 * 5 ? isLit : isLit ? false : row[index - 1]
                )
              : row
          )
        );
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [currentWave]);

  useEffect(() => {
    if (currentWave === 3) {
      let toggle = false;
      const intervalId = setInterval(() => {
        setDotStates((prev) =>
          prev.map((row, i) => {
            if (i === 2) {
              toggle = !toggle;
              return toggle ? bottomClearWave3 : initialWave2;
            }
            return row;
          })
        );
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [currentWave]);

  useEffect(() => {
    if (currentWave === 4) {
      let toggle = false;
      const intervalId = setInterval(() => {
        setDotStates((prev) =>
          prev.map((row, i) => {
            if (i === 3) {
              toggle = !toggle;
              return toggle ? bottomClearWave3 : initialWave4;
            }
            return row;
          })
        );
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [currentWave]);

  const switchWave = (newWave: number) => {
    setCurrentWave(newWave);
    if (newWave === 2 || newWave === 3) {
      setDotStates((prev) =>
        prev.map((row, i) => (i === newWave - 1 ? initialWave2 : row))
      );
    }
  };

  return (
    <Wrapper>
      <Title>Laser Maze 4</Title>
      <LaserMazeContainer>
        <Button
          onClick={() => switchWave(currentWave - 1)}
          disabled={currentWave === 1}
        >
          Previous Wave
        </Button>
        <CenteredContainer>
          <CirclesGrid dotState={dotStates[currentWave - 1]} />
        </CenteredContainer>
        <Button
          onClick={() => switchWave(currentWave + 1)}
          disabled={currentWave === 5}
        >
          Next Wave
        </Button>
      </LaserMazeContainer>
    </Wrapper>
  );
};
