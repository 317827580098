import { useAppSelector } from "@/hooks/useAppSelector";
import { selectBadgesState } from "@/redux/reducers/badges/reducer";
import { AddBadge, BadgesTable } from "./components";
import {
  AdminContainer,
  HeaderSection,
  PageTitle,
  ScrollableContent,
} from "./styles";

export const Badges = (): JSX.Element => {
  const { error, isLoading, badges } = useAppSelector(selectBadgesState);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <AdminContainer>
      <HeaderSection>
        <PageTitle>Manage Badges</PageTitle>
        <AddBadge />
      </HeaderSection>
      <ScrollableContent>
        <BadgesTable badges={badges} />
      </ScrollableContent>
    </AdminContainer>
  );
};
