// styles.ts
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

export const GameWrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.33);
  margin-bottom: 8px;
  padding: 8px;
  width: 100%;
  max-width: 600px;
`;

export const ScoreWrapper = styled.div`
  margin: 8px 0;
`;

export const Label = styled.strong`
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 20px;
`;

export const KeyInput = styled.input`
  margin-right: 20px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 180px;
`;

export const Button = styled.button`
  margin-top: 16px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  button {
    flex: 1;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const RemoveButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;

  button {
    background-color: #dc3545;

    &:hover {
      background-color: #c82333;
    }
  }
`;
