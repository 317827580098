import { GAME_TITLES } from "./constants";
import { useTournamentOfChampionsHook } from "./hook";
import {
  Container,
  GameTable,
  OverallRanking,
  StyledOverallTable,
  Table,
  TablesContainer,
} from "./styles";

export const TournamentOfChampions = (): JSX.Element => {
  const { sortedOverallScores, displayName, playerData } =
    useTournamentOfChampionsHook();

  return (
    <Container>
      <OverallRanking>
        <h2>ToC 2026</h2>
        <StyledOverallTable>
          <thead>
            <tr>
              <th>Player</th>
              <th>Total Score</th>
            </tr>
          </thead>
          <tbody>
            {sortedOverallScores.map(({ player, totalScore }, index) => {
              const hiddenIndex = sortedOverallScores
                .slice(0, index)
                .filter((e) => e.player.hidden).length;
              return (
                <tr key={index}>
                  <td>{displayName(player, hiddenIndex)}</td>
                  <td>{totalScore}</td>
                </tr>
              );
            })}
          </tbody>
        </StyledOverallTable>
      </OverallRanking>

      <TablesContainer>
        {GAME_TITLES.map((game) => {
          const sortedPlayers = [...playerData].sort(
            (a, b) => b.scores[game] - a.scores[game]
          );

          return (
            <GameTable key={game}>
              <h3>{game}</h3>
              <Table>
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedPlayers.map((player, index) => {
                    const hiddenIndex = sortedPlayers
                      .slice(0, index)
                      .filter((p) => p.hidden).length;
                    return (
                      <tr key={index}>
                        <td>{displayName(player, hiddenIndex)}</td>
                        <td>{player.scores[game]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </GameTable>
          );
        })}
      </TablesContainer>
    </Container>
  );
};
