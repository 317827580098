import React from "react";
import { Container, Circle, Dot } from "./styles";
import { CirclesGridProps } from "./types";

export const CirclesGrid = React.memo(
  ({ dotState }: CirclesGridProps): JSX.Element => {
    const circles = Array.from({ length: 180 }, (_, index) => index + 1);

    return (
      <Container>
        {circles.map((circle) => {
          const isLitUp = dotState[circle - 1]; // Zero-based index

          return (
            <Circle key={circle} $isLitUp={isLitUp}>
              <Dot $isLitUp={isLitUp} />
            </Circle>
          );
        })}
      </Container>
    );
  }
);
