import { useCallback, useState } from "react";
import { UsePlayerLinksTableHookResponse } from "./types";

export const usePlayerLinksTableHook = (): UsePlayerLinksTableHookResponse => {
  const [isEmailVisible, setIsEmailVisible] = useState(false);

  const toggleEmailVisibility = useCallback((): void => {
    setIsEmailVisible((isEmailVisible) => !isEmailVisible);
  }, []);

  return { isEmailVisible, toggleEmailVisibility };
};
