import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { AuthState, User } from "./types";

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  isLoading: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.isAuthenticated = Boolean(action.payload);
      state.user = action.payload;
    },
  },
});

export const {
  reducer: authReducer,
  actions: { setIsLoading, loginSuccess, logout, setUser },
} = authSlice;

export const selectAuthState = (state: RootState) => state.auth;
