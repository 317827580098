import { BoundPlayers, SearchPlayer } from "./components";
import { BackButton } from "./components/BackButton";

import { LoadingDiv } from "../../components/LoadingDiv";
import { LoadingSpinnerOverlay } from "../../components/LoadingSpinnerOverlay";
import { useTypedSelector } from "../../hooks";
import { ChooseLocationModal } from "./components";
import { SideMenu } from "./components/SideMenu/component";
import { useActivateHook } from "./hook";
import {
  BackgroundImage,
  Content,
  Information,
  LineDivider,
  SearchWrapper,
  StarIcon,
  StarWrapper,
  Wrapper,
  WrapperPlayerBasicInfo,
} from "./styles";

export const Activate = (): JSX.Element | null => {
  const {
    playerInfo: { player, isLoading, isLoadingCompareAction },
  } = useTypedSelector(({ playerInfo }) => ({
    playerInfo,
  }));

  const {
    menuActive,
    onRoomClick,
    room,
    handleBackButtonClick,
    isModalOpen,
    setIsModalOpen,
    setMenuActive,
    renderContent,
    renderHeader,
  } = useActivateHook();

  return (
    <BackgroundImage>
      <ChooseLocationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // TODO: needs to be worked on in the future
        // showDontAskAgainCheckbox
        menuActive={menuActive}
      />
      {renderHeader()}

      <Wrapper>
        {player.player_name ? (
          <div>
            {isLoadingCompareAction && <LoadingSpinnerOverlay />}
            <BackButton onClick={handleBackButtonClick} />
            <WrapperPlayerBasicInfo>
              <div>{player.player_name}</div>
              <StarWrapper>
                <StarIcon /> {player.stars} Stars
              </StarWrapper>
            </WrapperPlayerBasicInfo>
            <LineDivider />
            <Content>
              <SideMenu
                menuActive={menuActive}
                onRoomClick={onRoomClick}
                room={room}
                setMenuActive={setMenuActive}
              />
              <Information>{renderContent()}</Information>
            </Content>
          </div>
        ) : (
          <>
            {isLoading ? (
              <LoadingDiv />
            ) : (
              <SearchWrapper>
                <SearchPlayer />
                <BoundPlayers />
              </SearchWrapper>
            )}
          </>
        )}
      </Wrapper>
    </BackgroundImage>
  );
};
