import { Badge } from "@/redux/models/BadgesDB";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPlayerBadges, updatePlayerBadges } from "./thunk";
import { PlayerBadgesState } from "./types";

const initialState: PlayerBadgesState = {
  playerBadges: [],
  isLoading: false,
  error: null,
};

const playerBadgesSlice = createSlice({
  name: "playerBadges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerBadges.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchPlayerBadges.fulfilled,
        (state, action: PayloadAction<Badge[]>) => {
          state.isLoading = false;
          state.playerBadges = action.payload;
        }
      )
      .addCase(fetchPlayerBadges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updatePlayerBadges.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updatePlayerBadges.fulfilled,
        (state, action: PayloadAction<Badge[] | undefined>) => {
          state.isLoading = false;
          state.playerBadges = action.payload || []; // Overwrite badges with new list
        }
      )
      .addCase(updatePlayerBadges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { reducer: playerBadgesReducer } = playerBadgesSlice;

export const selectPlayerBadges = (state: RootState) => state.playerBadges;
