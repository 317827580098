import { Checkbox } from "../../../../components/Checkbox";
import { ChooseLocationModalProps } from "./types";
import { ADDITIONAL_STYLES } from "./constants";
import {
  Button,
  ChooseLocationTitle,
  Modal,
  UnorderedLocationList,
} from "./styles";
import { useChooseLocationModalHook } from "./hook";
import React from "react";

export const ChooseLocationModal = React.memo(
  ({
    isModalOpen,
    shouldCloseOnEsc = false,
    shouldCloseOnOverlayClick = false,
    setIsModalOpen,
    showDontAskAgainCheckbox = false,
    menuActive,
  }: ChooseLocationModalProps): JSX.Element => {
    const { locations, onDontAskAgainClick, onLocationClick, dontAskAgain } =
      useChooseLocationModalHook({
        menuActive,
        setIsModalOpen,
      });

    Modal.setAppElement("#root");

    return (
      <Modal
        isOpen={isModalOpen}
        contentLabel="Select a Location"
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ChooseLocationTitle>Select a Location</ChooseLocationTitle>
        <UnorderedLocationList>
          {locations.map((location) => (
            <Button key={location} onClick={onLocationClick(location)}>
              {location}
            </Button>
          ))}
        </UnorderedLocationList>
        {showDontAskAgainCheckbox && (
          <Checkbox
            additionalStyles={ADDITIONAL_STYLES}
            value={dontAskAgain}
            onValueChange={onDontAskAgainClick}
            label="Don't ask again"
            data-trackid="don't ask again (location)"
          />
        )}
      </Modal>
    );
  }
);
