import React from "react";
// import { EditBadge } from "../EditBadge/component";
import { EditBadge } from "..";
import { useBadgesHook } from "./hook";
import { ActionLink, Table, TableCell, TableHeader, TableRow } from "./styles";
import { BadgesTableProps } from "./types";

export const BadgesTable = React.memo(
  ({ badges }: BadgesTableProps): JSX.Element => {
    const {
      onDeleteClick,
      onDismissClick,
      onUpdateClick,
      isEditing,
      selectedBadge,
    } = useBadgesHook();

    return (
      <>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Title</TableHeader>
              <TableHeader>Description</TableHeader>
              <TableHeader>Actions</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {badges.map((badge) => (
              <TableRow key={badge._id}>
                <TableCell>{badge.title}</TableCell>
                <TableCell>{badge.description}</TableCell>
                <TableCell>
                  <ActionLink onClick={onUpdateClick(badge)}>Update</ActionLink>
                  <ActionLink onClick={onDeleteClick(badge._id)} $danger>
                    Remove
                  </ActionLink>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
        {isEditing && selectedBadge && (
          <EditBadge badge={selectedBadge} onClose={onDismissClick} />
        )}
      </>
    );
  }
);
