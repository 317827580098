import styled, { keyframes } from "styled-components";
import { Link } from "../../../../components";
import { LevelLinkProps } from "./types";

// 🔄 Keyframes for Spinner Animation
const prixClipFix = keyframes`
  0% { clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0); }
  25% { clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0); }
  50% { clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%); }
  75% { clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%); }
  100% { clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0); }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
`;

export const Grid = styled.div`
  border: 2px solid black;
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Cell = styled.div`
  flex: 1;
  border: 1px solid black;
  text-align: center;
`;

export const SecondsLeft = styled.div`
  text-align: center;
  font-size: 24px;
`;

export const TargetsLeft = styled.p`
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: blue;
`;

export const Congratulations = styled.p`
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: green;
`;

export const Button = styled.button`
  margin: 16px 0;
`;

export const LoadingSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CalibratingText = styled.p`
  font-size: 96px;
`;

export const CalibratingSpinner = styled.span`
  font-size: 1px;
  width: 48px;
  height: 48px;
  border: 4px solid grey;
  border-radius: 50%;
  position: relative;
  transform: rotate(45deg);
  box-sizing: border-box;

  &::before {
    content: "";
    position: absolute;
    inset: -20px;
    border-radius: 50%;
    border: 4px solid white;
    animation: ${prixClipFix} 2s infinite linear;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LevelsWrapper = styled.div`
  position: absolute;
  right: 0;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
`;

export const LevelTitle = styled.p`
  text-align: center;
  font-weight: 800;
  font-size: 24px;
  margin-top: 0;
`;

export const LevelLink = styled(Link)<LevelLinkProps>`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  background-color: ${({ $isSelected }) => $isSelected && "lightcoral"};
`;
