import { SearchInputProps } from "./types";
import {
  Button,
  Error,
  Input,
  SearchInputWithErrorWrapper,
  Wrapper,
} from "./styles";
import { useSearchInputHook } from "./hook";

export const SearchInput = ({
  onSearchClick,
  buttonLabel = "Search",
  placeholder = "",
  error = "",
  size = "large",
  focusOnRender = false,
  disabled = false,
}: SearchInputProps): JSX.Element => {
  const { inputRef, handleOnSearch, text, onInputChange, onInputKeyDown } =
    useSearchInputHook({
      onSearchClick,
      focusOnRender,
    });

  return (
    <SearchInputWithErrorWrapper>
      <Wrapper>
        <Input
          innerRef={inputRef}
          $size={size}
          placeholder={placeholder}
          onChange={onInputChange}
          value={text}
          onKeyDown={onInputKeyDown}
          disabled={disabled}
        />
        {!disabled && (
          <Button $size={size} onClick={handleOnSearch}>
            {buttonLabel}
          </Button>
        )}
      </Wrapper>
      <Error>{error}</Error>
    </SearchInputWithErrorWrapper>
  );
};
