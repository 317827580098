import { createRef, useCallback, useEffect, useState } from "react";
import { UseSearchInputHookProps, UseSearchInputHookResponse } from "./types";

export const useSearchInputHook = ({
  onSearchClick,
  focusOnRender,
}: UseSearchInputHookProps): UseSearchInputHookResponse => {
  const inputRef = createRef<HTMLInputElement>();
  const [text, setText] = useState("");

  useEffect(() => {
    if (focusOnRender) {
      inputRef.current?.focus();
    }
  }, [focusOnRender, inputRef]);

  const handleOnSearch = useCallback(() => {
    onSearchClick(text);
    setText("");
  }, [text, onSearchClick]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value),
    [setText]
  );

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) =>
      e.key === "Enter" && handleOnSearch(),
    [handleOnSearch]
  );

  return { inputRef, handleOnSearch, text, onInputChange, onInputKeyDown };
};
