import { useCallback } from "react";
import { useActions, useTypedSelector } from "../../../../hooks";
import { UseSearchPlayerHookResponse } from "./types";

export const useSearchPlayerHook = (): UseSearchPlayerHookResponse => {
  const {
    playerInfo: { errorMessage },
    lastUpdated: { isApiDown },
  } = useTypedSelector(({ playerInfo, lastUpdated }) => ({
    playerInfo,
    lastUpdated,
  }));
  const { fetchPlayerInformation, clearPlayerAllLocations } = useActions();

  const handleSearchClick = useCallback(
    (text: string) => {
      fetchPlayerInformation(text);
      clearPlayerAllLocations();
    },
    [fetchPlayerInformation, clearPlayerAllLocations]
  );

  return { errorMessage, handleSearchClick, isApiDown };
};
