import styled from "styled-components";

export const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #1e1e1e;
  border-radius: 8px;
  max-width: 900px;
  margin: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const PageTitle = styled.h2`
  margin: 0;
  color: white;
  text-align: center;
`;

export const ScrollableContent = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #333;
  border-radius: 8px;
  overflow: hidden;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #444;
  &:nth-child(even) {
    background: #2a2a2a;
  }
`;

export const TableHeader = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #222;
  color: white;
  font-weight: bold;
`;

export const TableCell = styled.td`
  padding: 12px;
  color: #ddd;
`;

export const ActionLink = styled.button<{ $danger?: boolean }>`
  background: none;
  border: none;
  color: ${({ $danger }) => ($danger ? "#ff4d4d" : "#4da6ff")};
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background: #333;
  padding: 10px;
  border-radius: 8px;
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #222;
  color: white;
  flex: 1;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const Button = styled.button`
  padding: 10px 15px;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.3s ease;

  &:hover {
    background: #0056b3;
  }
`;
