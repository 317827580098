import styled from "styled-components";
import { VscStarFull } from "react-icons/vsc";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const StarIcon = styled(VscStarFull)`
  font-size: 196px;
`;

export const StarsCountWrapper = styled.div`
  margin-left: 48px;
`;

export const StarsCount = styled.div`
  font-size: 64px;
  font-weight: 600;
`;

export const Text = styled.div`
  font-size: 56px;
  text-align: left;
`;
