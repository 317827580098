import React from "react";
import { Wrapper } from "./styles";
import { GameCardProps } from "./types";
import { useGameCardHook } from "./hook";

export const GameCard = React.memo(
  ({ title, onGameClick, selectedGame }: GameCardProps): JSX.Element => {
    const { isSelected } = useGameCardHook({ selectedGame, title });

    return (
      <Wrapper onClick={() => onGameClick(title)} $isSelected={isSelected}>
        {title}
      </Wrapper>
    );
  }
);
