import styled from "styled-components";

export const ScoreDifferenceCommon = styled.span`
  margin-left: 4px;
`;

export const ScoreDifferenceNegative = styled(ScoreDifferenceCommon)`
  color: red;
`;

export const ScoreDifferencePositive = styled(ScoreDifferenceCommon)`
  color: green;
`;
