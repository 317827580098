import React from "react";
import { Wrapper } from "./styles";

export const PageNotFound = React.memo((): JSX.Element => {
  return (
    <Wrapper>
      <strong>404 - Page Not Found</strong>
    </Wrapper>
  );
});
