import { useState } from "react";
import { Locations, PlayerLinks } from "./components";
import { Badges } from "./components/Badges/component";
import { Container, TabButton, Tabs } from "./styles";
import { AdminTab } from "./types";

export const Admin = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<AdminTab>("playerLinks");

  return (
    <Container>
      <h2>Admin Panel</h2>
      <Tabs>
        <TabButton
          $active={activeTab === "playerLinks"}
          onClick={() => setActiveTab("playerLinks")}
        >
          Player Links
        </TabButton>
        <TabButton
          $active={activeTab === "locations"}
          onClick={() => setActiveTab("locations")}
        >
          Locations
        </TabButton>
        <TabButton
          $active={activeTab === "badges"}
          onClick={() => setActiveTab("badges")}
        >
          Badges
        </TabButton>
      </Tabs>

      {activeTab === "playerLinks" && <PlayerLinks />}
      {activeTab === "locations" && <Locations />}
      {activeTab === "badges" && <Badges />}
    </Container>
  );
};
