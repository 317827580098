import { BREAKPOINT } from "@/styles/constants";
import styled from "styled-components";
import { TextInput } from "../../../../components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px;

  @media (max-width: ${BREAKPOINT.mobile}) {
    padding: 10px;
  }
`;

export const KeyValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  overflow-x: auto;

  @media (max-width: ${BREAKPOINT.mobile}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Label = styled.strong`
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 20px;
`;

export const KeyInput = styled(TextInput)`
  margin-right: 20px;
  text-align: center;
  width: 25px;
  font-weight: 600;
`;

export const Button = styled.button`
  cursor: pointer;
  margin-left: 8px;
  padding: 0 8px;
`;
