import styled from "styled-components";
import { NewTag } from "../NewTag";

export const Wrapper = styled.div`
  margin-left: 4px;
`;

export const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  margin-right: 8px;
`;

export const Label = styled.label``;

export const NewTagIcon = styled(NewTag)`
  margin-left: 8px;
`;
