import { TextInput } from "../../../../components";
import { Button, KeyInput, Label, Wrapper } from "./styles";
import { useBoundPlayersHook } from "./hook";
import React from "react";

export const BoundPlayers = React.memo((): JSX.Element | null => {
  const {
    boundPlayers,
    isApiDown,
    onTextChange,
    saveBoundPlayersOnBlur,
    onGoButtonClick,
  } = useBoundPlayersHook();

  if (!boundPlayers) {
    return null;
  }

  return (
    <Wrapper>
      <h1>Bound Players</h1>
      <div>
        {Object.entries(boundPlayers).map(([key, value]) => (
          <div key={key}>
            <Label>KEY</Label>
            <KeyInput value={key} disabled />
            <Label>VALUE</Label>
            <TextInput
              value={value}
              placeholder="Enter profile name"
              onChange={onTextChange(key)}
              onBlur={saveBoundPlayersOnBlur}
            />
            {!isApiDown && <Button onClick={onGoButtonClick(value)}>Go</Button>}
          </div>
        ))}
      </div>
    </Wrapper>
  );
});
