import { useAppDispatch } from "@/hooks/useAppDispatch";
import { useAppSelector } from "@/hooks/useAppSelector";
import { selectPlayerLinksState } from "@/redux/reducers/playerLinks/reducer";
import { resetPlayerLink } from "@/redux/reducers/playerLinks/thunk";
import { useCallback, useMemo } from "react";
import { ActionLink } from "./styles";
import { UseApprovedPlayerLinksTableResponse } from "./types";

export const useApprovedPlayerLinksTable =
  (): UseApprovedPlayerLinksTableResponse => {
    const dispatch = useAppDispatch();
    const { playerLinks } = useAppSelector(selectPlayerLinksState);

    const approvedPlayers = useMemo(
      () => playerLinks.filter(({ approved }) => approved === true),
      [playerLinks]
    );

    const onResetClick = useCallback(
      (id: string) => () => {
        dispatch(resetPlayerLink(id));
      },
      [dispatch]
    );

    const actions = (id: string): JSX.Element => (
      <ActionLink onClick={onResetClick(id)}>Reset</ActionLink>
    );

    return { approvedPlayers, actions };
  };
