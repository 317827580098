import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-right: 8px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ListPlayersContainer = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
`;

export const MagentaSquare = styled.span`
  display: inline-block;
  margin-left: 16px;
  margin-right: 10px;
  height: 10px;
  width: 10px;
  background-color: magenta;
`;
