import { useAppDispatch } from "@/hooks/useAppDispatch";
import { addLocation } from "@/redux/reducers/locations/thunk";
import { useCallback, useState } from "react";
import { UseAddLocationHookResponse } from "./types";

export const useAddLocationHook = (): UseAddLocationHookResponse => {
  const [locationName, setLocationName] = useState("");
  const dispatch = useAppDispatch();

  const onInputNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocationName(e.target.value);
    },
    []
  );

  const onAddLocationClick = (e: React.FormEvent) => {
    e.preventDefault();

    if (!locationName.trim()) {
      return;
    }

    dispatch(addLocation({ name: locationName }));
    setLocationName("");
  };

  return {
    locationName,
    onInputNameChange,
    onAddLocationClick,
  };
};
