import styled, { css } from "styled-components";
import { WrapperProps } from "./types";

export const Wrapper = styled.button<WrapperProps>`
  min-width: 23%;
  text-transform: uppercase;
  height: 50px;
  border-radius: 4px;
  background-color: #1e1e1e;
  box-shadow: 5px 5px black;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(225, 245, 235, 0.3);
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 1px solid white;
    `}
`;
