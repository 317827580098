import styled from "styled-components";

export const Container = styled.div`
  width: 960px; /* Set max width */
  max-width: 90vw; /* Responsive width */
  height: 90vh; /* Extend close to top and bottom */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Keep content aligned at the top */
  background-color: #2d2d2d;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

export const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
`;

export const AdminContainer = styled.div`
  width: 960px;
  max-width: 90vw;
  height: 90vh; /* Make it take most of the screen height */
  display: flex;
  flex-direction: column;
  background-color: #2d2d2d;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

export const HeaderSection = styled.div`
  position: sticky;
  top: 0;
  background-color: #2d2d2d;
  padding-bottom: 10px;
  z-index: 10;
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto; /* Enables vertical scrolling */
  margin-top: 10px;
`;
