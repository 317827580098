import { activateAPI } from "@/api";
import { useAppDispatch } from "@/hooks/useAppDispatch";
import { useAppSelector } from "@/hooks/useAppSelector";
import { logout, selectAuthState } from "@/redux/reducers/auth/reducer";
import { getAuthRedirectUrl, maskEmail } from "@/utils";
import { useCallback, useMemo } from "react";
import { UseTopBarHookResponse } from "./types";

export const useTopBarHook = (): UseTopBarHookResponse => {
  const dispatch = useAppDispatch();

  const { isAuthenticated, user, isLoading } = useAppSelector(selectAuthState);

  const handleLogin = useCallback((): void => {
    window.location.href = `${getAuthRedirectUrl()}/auth/google`;
  }, []);

  const handleLogout = useCallback(async (): Promise<void> => {
    await activateAPI.get("/auth/logout");
    dispatch(logout());
  }, [dispatch]);

  const isAdmin = useMemo(() => user?.role === "admin", [user?.role]);

  const welcomeMessage = useMemo(
    () =>
      `Welcome, ${user?.playerName ? user.playerName : maskEmail(user?.email)}`,
    [user]
  );

  return {
    handleLogin,
    handleLogout,
    isAuthenticated,
    isLoading,
    isAdmin,
    welcomeMessage,
  };
};
