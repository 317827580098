import { INACTIVE_GAME_ID } from "../../constants";
import { ChooseLocationModal } from "..";

import { SideMenuProps } from "./types";
import { Menu, Row, SubMenu } from "./styles";
import { useSideMenuHook } from "./hook";
import React from "react";

export const SideMenu = React.memo(
  ({
    menuActive,
    setMenuActive,
    room,
    onRoomClick,
  }: SideMenuProps): JSX.Element => {
    const {
      isMenuActive,
      onMenuChange,
      location,
      getFilterCountPerRoom,
      isComparingWithOtherLocations,
      isComparingOldScores,
      clearPlayerYesterday,
      clearPlayerAllLocations,
      fetchPlayerAllLocations,
      fetchPlayerYesterday,
      hasMoreThanOneLocation,
      onSwitchLocationClick,
      isModalOpen,
      setIsModalOpen,
    } = useSideMenuHook({
      menuActive,
      setMenuActive,
    });

    return (
      <>
        <Menu>
          <Row
            $isSelected={isMenuActive("Account Summary")}
            onClick={() => onMenuChange("Account Summary")}
          >
            Account Summary
          </Row>
          <Row
            $isSelected={isMenuActive("Games")}
            onClick={() => onMenuChange("Games")}
          >
            Games
            {isMenuActive("Games") && (
              <SubMenu>
                {location.rooms
                  .filter(({ id }) => id !== INACTIVE_GAME_ID)
                  .map(({ name, id }, i) => (
                    <Row
                      $isSelected={room.roomId === id}
                      key={`${i + 1}-${name}`}
                      onClick={() => {
                        onRoomClick({ roomName: name, roomId: id });
                      }}
                    >
                      {name} {getFilterCountPerRoom(id)}
                    </Row>
                  ))}
              </SubMenu>
            )}
          </Row>
          {isMenuActive("Games") && (
            <>
              {!isComparingWithOtherLocations && (
                <li
                  onClick={
                    isComparingOldScores
                      ? clearPlayerYesterday
                      : fetchPlayerYesterday
                  }
                >
                  {isComparingOldScores
                    ? "Clear Comparison"
                    : "Compare with Yesterday"}
                </li>
              )}
              {hasMoreThanOneLocation && !isComparingOldScores && (
                <li
                  onClick={
                    isComparingWithOtherLocations
                      ? clearPlayerAllLocations
                      : fetchPlayerAllLocations
                  }
                >
                  {isComparingWithOtherLocations
                    ? "Clear Comparison"
                    : "Compare Other Locations"}
                </li>
              )}
            </>
          )}
          <Row
            $isSelected={isMenuActive("Leaderboards")}
            onClick={() => onMenuChange("Leaderboards")}
          >
            Leaderboards
          </Row>
          <Row
            $isSelected={isMenuActive("World Leaderboard")}
            onClick={() => onMenuChange("World Leaderboard")}
          >
            World Leaderboard
          </Row>
          <Row
            $isSelected={isMenuActive("Badges")}
            onClick={() => onMenuChange("Badges")}
          >
            Badges
          </Row>
          {hasMoreThanOneLocation && (
            <li onClick={onSwitchLocationClick}>Switch Locations</li>
          )}
        </Menu>
        <ChooseLocationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          menuActive={menuActive}
        />
      </>
    );
  }
);
