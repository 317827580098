import { useMemo } from "react";
import {
  UseLeaderboardScoreDifferenceHookProps,
  UseLeaderboardScoreDifferenceHookResponse,
} from "./types";

export const useLeaderboardScoreDifferenceHook = ({
  scoreDifference,
}: UseLeaderboardScoreDifferenceHookProps): UseLeaderboardScoreDifferenceHookResponse => {
  const isScorePositive = useMemo(
    (): boolean => scoreDifference > 0,
    [scoreDifference]
  );

  return { isScorePositive };
};
