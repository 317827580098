import { useCallback, useState } from "react";
import {
  UseChooseLocationModalProps,
  UseChooseLocationModalResponse,
} from "./types";
import { useActions, useTypedSelector } from "../../../../hooks";

export const useChooseLocationModalHook = ({
  menuActive,
  setIsModalOpen,
}: UseChooseLocationModalProps): UseChooseLocationModalResponse => {
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const {
    locations,
    player: { player_name },
    playerBeingSearched,
  } = useTypedSelector(({ playerInfo }) => playerInfo);
  const { fetchPlayerInformation, fetchLeaderboard } = useActions();

  const onDontAskAgainClick = useCallback(() => {
    setDontAskAgain((dontAskAgain) => !dontAskAgain);
  }, [setDontAskAgain]);

  const onLocationClick = useCallback(
    (location: string) => () => {
      fetchPlayerInformation(player_name || playerBeingSearched, location);
      (menuActive === "Leaderboards" || menuActive === "World Leaderboard") &&
        fetchLeaderboard(menuActive === "Leaderboards" ? location : "world");
      setIsModalOpen(false);
    },
    [
      fetchPlayerInformation,
      player_name,
      playerBeingSearched,
      menuActive,
      setIsModalOpen,
      fetchLeaderboard,
    ]
  );

  return { locations, onDontAskAgainClick, onLocationClick, dontAskAgain };
};
