import { useAppDispatch } from "@/hooks/useAppDispatch";
import { Location } from "@/redux/models/LocationDB";
import { deleteLocation } from "@/redux/reducers/locations/thunk";
import { useCallback, useState } from "react";
import { UseLocationsHookResponse } from "./types";

export const useLocationsHook = (): UseLocationsHookResponse => {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<Location>();

  const onDeleteClick = useCallback(
    (id: string) => (): void => {
      dispatch(deleteLocation(id));
    },
    [dispatch]
  );

  const onUpdateClick = useCallback(
    (location: Location) => (): void => {
      setIsEditing(true);
      setSelectedLocation(location);
    },
    []
  );

  const onDismissClick = useCallback((): void => {
    setIsEditing(false);
    setSelectedLocation(undefined);
  }, []);

  return {
    onDeleteClick,
    onUpdateClick,
    onDismissClick,
    isEditing,
    selectedLocation,
  };
};
