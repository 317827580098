import { useState } from "react";
import { Link } from "..";
import { LoginModal } from "./components";
import { useTopBarHook } from "./hook";
import {
  AdminLink,
  Button,
  InnerWrapper,
  OuterWrapper,
  UserSection,
} from "./styles";

export const TopBar = (): JSX.Element | null => {
  const { handleLogout, isAuthenticated, isLoading, isAdmin, welcomeMessage } =
    useTopBarHook();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (isLoading) {
    return null;
  }

  return (
    <OuterWrapper>
      <InnerWrapper>
        <Link to="/strategy/1">Play Strategy</Link>
        {isAuthenticated ? (
          <UserSection>
            <span>{welcomeMessage}</span>
            {isAdmin && <AdminLink to="/admin">Admin Page</AdminLink>}
            <Button onClick={handleLogout}>Logout</Button>
          </UserSection>
        ) : (
          <Button onClick={openModal}>Login</Button>
        )}
      </InnerWrapper>
      {isModalOpen && <LoginModal onClose={closeModal} />}
    </OuterWrapper>
  );
};
