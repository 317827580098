import React from "react";
import { Overlay, Spinner } from "./styles";

export const LoadingSpinnerOverlay = React.memo((): JSX.Element => {
  return (
    <Overlay>
      <Spinner />
    </Overlay>
  );
});
