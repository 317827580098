import styled from "styled-components";
import { PentagonProps } from "./types";
import { INNER_PENTAGON_SIZE, PENTAGON_SIZE, RANK_COLORS } from "./constants";

export const Wrapper = styled.div`
  position: absolute;
`;

export const Pentagon = styled.div<PentagonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${PENTAGON_SIZE};
  height: ${PENTAGON_SIZE};
  clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
  background-color: ${({ $rank }) => RANK_COLORS[$rank] ?? "gray"};
`;

export const InnerPentagon = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  width: ${INNER_PENTAGON_SIZE};
  height: ${INNER_PENTAGON_SIZE};
  z-index: 500;
  clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RankText = styled.span`
  z-index: 1000;
  color: white;
  font-weight: 600;
  font-size: 1.875rem;
  display: flex;
  justify-content: center;
`;
