import styled from "styled-components";
import { ActionLinkProps } from "./types";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #2c2c2c; /* Darker gray to match dark themes */
  color: #fff;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #444;

  &:nth-child(even) {
    background-color: #383838; /* Alternate row color */
  }
`;

export const TableHeader = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #444; /* Darker header */
  color: #fff;
`;

export const TableCell = styled.td`
  padding: 12px;
`;

export const ActionLink = styled.a<ActionLinkProps>`
  margin-right: 12px;
  color: ${({ $danger }) =>
    $danger ? "#ff5252" : "#66b3ff"}; /* Red for danger, blue for other links */
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
