import ReactModal from "react-modal";
import styled from "styled-components";

export const Modal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  background-color: #1e1e1e;
  color: white;
  font-size: 20px;
  outline: none;
`;

export const ChooseLocationTitle = styled.h2`
  margin-top: 0;
`;

export const UnorderedLocationList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Button = styled.li`
  cursor: pointer;
  background-color: grey;
  color: white;
  font-size: 24px;
  text-decoration: none;
  border: 0.5px solid;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  margin-top: 8px;

  &:hover {
    background: chocolate;
    color: white;
  }
`;
