import styled from "styled-components";
import { RowProps, TitleProps } from "./types";
import ReactModal from "react-modal";

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FiltersWrapper = styled.div`
  margin-left: 24px;
`;

export const ButtonsWrapper = styled.div`
  margin-right: 24px;
  margin-top: 24px;
`;

export const Button = styled.button`
  background-color: grey;
  color: white;
  font-size: 24px;
  text-decoration: none;
  border: 0.5px solid;
  padding: 8px;
  border-radius: 4px;
  margin-left: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const Title = styled.th<TitleProps>`
  font-size: 24px;
  background-color: mediumvioletred;
  cursor: ${(props) => props.$isClickable && "pointer"};
`;

export const Row = styled.tr<RowProps>`
  cursor: pointer;
  &:hover {
    background-color: greenyellow;
    color: black;
  }
  background-color: ${(props) => props.$isSelected && "olivedrab"};
  color: ${(props) => props.$isSelected && "black"};
`;

export const IsNew = styled.td`
  font-size: 20px;
  padding: 0 8px;
`;

export const StarsColumn = styled.td`
  text-align: center;
`;

export const Modal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  background-color: #1e1e1e;
  color: white;
  font-size: 20px;
  outline: none;
`;

export const TextArea = styled.textarea`
  min-height: 200px;
  min-width: 400px;
`;

export const ModalActionsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  text-align: right;
`;
