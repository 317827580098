import React from "react";

import { GameCardList, GamesList } from "..";
import { ShowGameScoresProps } from "./types";
import { useShowGameScoresHook } from "./hook";

export const ShowGameScores = React.memo(
  ({ games, ...filters }: ShowGameScoresProps): JSX.Element => {
    const { isMultiGameView, selectedGame, setSelectedGame } =
      useShowGameScoresHook({ games });

    return (
      <>
        {!isMultiGameView && (
          <GameCardList
            selectedGame={selectedGame}
            onGameClick={setSelectedGame}
            games={games}
          />
        )}
        <GamesList
          games={
            isMultiGameView
              ? games
              : games.filter(({ name }) => selectedGame === name)
          }
          {...filters}
        />
      </>
    );
  }
);
