import { LeaderboardScoreDifferenceProps } from "./types";
import { ScoreDifference } from "./styles";
import { useLeaderboardScoreDifferenceHook } from "./hook";
import React from "react";

export const LeaderboardScoreDifference = React.memo(
  ({ scoreDifference = 0 }: LeaderboardScoreDifferenceProps): JSX.Element => {
    const { isScorePositive } = useLeaderboardScoreDifferenceHook({
      scoreDifference,
    });

    return (
      <>
        {Boolean(scoreDifference) && (
          <ScoreDifference $isPositive={isScorePositive}>
            ({isScorePositive && "+"}
            {scoreDifference})
          </ScoreDifference>
        )}
      </>
    );
  }
);
