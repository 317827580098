import { useCallback, useState } from "react";
import {
  useActions,
  useCompareOldScores,
  useOtherLocations,
  useTypedSelector,
} from "../../../../hooks";
import { UseFiltersHookResponse } from "./types";

export const useFiltersHook = (): UseFiltersHookResponse => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    toggleShowOnlyDifferentLevels,
    toggleShowOnlyLevelsToMatchScore,
    toggleShowOnlyLevelsToImprove,
    toggleShowOnlyMissingLevels,
    toggleShowOnlyNotTopScores,
    toggleShowOnlyTopScores,
    toggleMultiGameView,
    toggleShowScoreDifferenceGameLevel,
    toggleShowTotalScorePerGame,
  } = useActions();

  const {
    playerInfo: { comparePlayers },
    filterScores: {
      showOnlyDifferentLevels,
      showOnlyLevelsToMatchScore,
      showOnlyLevelsToImprove,
      showOnlyMissingLevels,
      showOnlyNotTopScores,
      showOnlyTopScores,
      isMultiGameView,
      showScoreDifference,
      showTotalScorePerGame,
    },
  } = useTypedSelector(({ playerInfo, filterScores }) => ({
    playerInfo,
    filterScores,
  }));

  const { isComparingWithOtherLocations } = useOtherLocations();
  const { isComparingOldScores } = useCompareOldScores();

  const openModal = useCallback((): void => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = useCallback((): void => {
    setIsModalOpen(false);
  }, []);

  return {
    closeModal,
    openModal,
    isModalOpen,
    isComparingOldScores,
    isComparingWithOtherLocations,
    isMultiGameView,
    toggleMultiGameView,
    showScoreDifference,
    showOnlyDifferentLevels,
    showOnlyLevelsToMatchScore,
    showOnlyLevelsToImprove,
    showOnlyMissingLevels,
    showOnlyNotTopScores,
    showOnlyTopScores,
    showTotalScorePerGame,
    comparePlayers,
    toggleShowOnlyDifferentLevels,
    toggleShowOnlyLevelsToImprove,
    toggleShowOnlyLevelsToMatchScore,
    toggleShowOnlyMissingLevels,
    toggleShowOnlyNotTopScores,
    toggleShowOnlyTopScores,
    toggleShowScoreDifferenceGameLevel,
    toggleShowTotalScorePerGame,
  };
};
