export const RANK_COLORS: Record<number, string> = {
  1: "white",
  2: "palevioletred",
  3: "blue",
  4: "darkorchid",
  5: "red",
  6: "orange",
  7: "yellow",
  8: "greenyellow",
};

export const PENTAGON_SIZE = "50px";

export const INNER_PENTAGON_SIZE = "42px";
