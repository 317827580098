import { RadioItem } from "../../../../components/RadioGroup/types";

export const IMPROVE_LEVELS_BY_DEFAULT_ITEMS: RadioItem[] = [
  {
    label: "200 or more",
    value: 200,
  },
  {
    label: "500 or more",
    value: 500,
  },
];
