import { combineReducers } from "redux";
import { albumsReducer } from "./albumReducer";
import { authReducer } from "./auth/reducer";
import { badgeReducer } from "./badge/reducer";
import { badgesReducer } from "./badges/reducer";
import { filterScoreReducer } from "./filterScoreReducer";
import { lastUpdatedReducer } from "./lastUpdatedReducer";
import { locationsReducer } from "./locations/reducer";
import { playerBadgesReducer } from "./playerBadges/reducer";
import { playerLinksReducer } from "./playerLinks/reducer";
import { scoreReducer } from "./scoreReducer";
import { strategyReducer } from "./strategyReducer";

export const reducers = combineReducers({
  albums: albumsReducer,
  auth: authReducer,
  playerInfo: scoreReducer,
  filterScores: filterScoreReducer,
  strategy: strategyReducer,
  lastUpdated: lastUpdatedReducer,
  badge: badgeReducer,
  locations: locationsReducer,
  playerLinks: playerLinksReducer,
  badges: badgesReducer,
  playerBadges: playerBadgesReducer,
});

export type RootState = ReturnType<typeof reducers>;
