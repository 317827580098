import { CloseButton, LoginOption, ModalContent, ModalOverlay } from "./styles";
import { LoginModalProps } from "./types";

export const LoginModal = ({ onClose }: LoginModalProps): JSX.Element => {
  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Choose a Login Method</h2>
        <LoginOption href={`${process.env.REACT_APP_SCORES_API}/auth/google`}>
          Login with Google
        </LoginOption>
        <LoginOption href={`${process.env.REACT_APP_SCORES_API}/auth/discord`}>
          Login with Discord
        </LoginOption>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};
