// styles.ts
import styled, { css } from "styled-components";
import { RowProps } from "./types";

export const OptionsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TableWrapper = styled.div`
  max-height: 60vh;
  min-height: 60vh;
  overflow-y: auto;
`;

export const Table = styled.table`
  margin: 0 auto;
  width: 90%;

  th {
    font-size: 24px;
    font-weight: 400;

    &:last-of-type {
      width: 240px;
    }
  }

  td {
    font-weight: 600;
    font-size: 24px;
  }
`;

// Add to your existing styles.ts

export const Row = styled.tr<RowProps>`
  ${({ $isCurrentUser }) =>
    $isCurrentUser &&
    css`
      background-color: rgba(255, 255, 255, 0.2);
    `}

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      background-color: lightsteelblue;
    `}

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const LeftAlign = styled.td`
  text-align: left;
`;

export const CenterAlign = styled.td`
  text-align: center;
`;

export const DifferenceWrapper = styled.td`
  text-align: left;
  display: flex;
  justify-content: space-between;
`;

export const Loading = styled.p`
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.004pt;
`;

export const Error = styled(Loading)`
  color: red;
`;

export const Highlight = styled.tr`
  background-color: lightsteelblue;
`;
