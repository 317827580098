import { PlayerLinksTable } from "../PlayerLinksTable";
import { useDeniedPlayerLinksTable } from "./hook";

export const DeniedPlayerLinksTable = (): JSX.Element | null => {
  const { deniedPlayers, actions } = useDeniedPlayerLinksTable();

  if (deniedPlayers.length === 0) {
    return null;
  }

  return (
    <PlayerLinksTable
      title="Denied"
      playerLinks={deniedPlayers}
      actions={actions}
    />
  );
};
