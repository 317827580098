import { useReducer, useMemo, useCallback } from "react";
import { useCompareOldScores, useTypedSelector } from "../../../../../../hooks";
import { UseLevelRowsHookResponse } from "./types";
import { setMonthlyScore } from "../../../../../../utils/monthlyScore";

export const useLevelRowsHook = (): UseLevelRowsHookResponse => {
  const { player, comparePlayers, playerYesterday, location } =
    useTypedSelector(({ playerInfo }) => playerInfo);

  const { isComparingOldScores } = useCompareOldScores();

  const [, forceRefresh] = useReducer((x) => x + 1, 0);

  const allPlayers = useMemo(
    () => [player, ...comparePlayers],
    [player, comparePlayers]
  );

  const isComparingWithOtherPlayers = useMemo(
    () => allPlayers.length > 1,
    [allPlayers]
  );

  const onScoreRowClick = useCallback(
    (gameId: number, levelId: number): void => {
      forceRefresh();
      setMonthlyScore({ player, location, gameId, levelId });
    },
    [player, location]
  );

  return {
    isComparingWithOtherPlayers,
    location,
    onScoreRowClick,
    player,
    allPlayers,
    isComparingOldScores,
    playerYesterday,
  };
};
