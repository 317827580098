import React from "react";
import { CellProps } from "./types";
import { TileText, TileWrapper } from "./styles";
import { useTileHook } from "./hook";

export const Tile = React.memo(({ color, onClick, content }: CellProps) => {
  const { handleClick, isClickable, memoizedContent } = useTileHook({
    content,
    onClick,
  });

  return (
    <TileWrapper $clickable={isClickable} $color={color} onClick={handleClick}>
      {memoizedContent && <TileText>{memoizedContent}</TileText>}
    </TileWrapper>
  );
});
