import styled from "styled-components";
import { TableRowProps } from "./types";

export const BadgeTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #333;
  border-radius: 8px;
  overflow: hidden;
`;

export const TableRow = styled.tr<TableRowProps>`
  border-bottom: 1px solid #444;
  background: ${({ $highlight }) => ($highlight ? "#4CAF50" : "#2a2a2a")};
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};

  &:nth-child(even) {
    background: ${({ $highlight }) => ($highlight ? "#45a049" : "#1e1e1e")};
  }

  &:hover {
    background: ${({ $clickable }) => ($clickable ? "#3a3a3a" : "inherit")};
  }
`;

export const TableHeader = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #222;
  color: white;
  font-weight: bold;
`;

export const TableCell = styled.td`
  padding: 12px;
  color: #ddd;
`;

export const SaveButton = styled.button`
  margin-top: 20px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: #0056b3;
  }
`;
