import { DownArrow, SamePosition, UpArrow } from "..";
import React from "react";
import { Position } from "./styles";
import { PositionDifferenceProps } from "./types";

export const PositionDifference = React.memo(
  ({ positionDifference }: PositionDifferenceProps): JSX.Element => {
    return (
      <Position>
        {!positionDifference ? (
          <SamePosition />
        ) : positionDifference > 0 ? (
          <UpArrow positionDifference={positionDifference} />
        ) : (
          <DownArrow positionDifference={positionDifference} />
        )}
      </Position>
    );
  }
);
