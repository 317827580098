import { exportPlayer } from "../../../../utils";
import { getMonthlyScore } from "../../../../utils/monthlyScore";
import { NewRank, Rank, Stars, TextInfo } from "./components";
import { useSummaryHook } from "./hook";
import {
  ExportDataButton,
  RequestLinkButton,
  ResetMonthlyScoreButton,
  StarsWrapper,
  Wrapper,
} from "./styles";
import { SummaryProps } from "./types";

export const Summary = ({ player, location }: SummaryProps): JSX.Element => {
  const {
    onExportDataClick,
    newRank,
    onResetMonthlyScoreClick,
    timesPassedTotal,
    topScores,
    onRequestLinkClick,
    isLoading,
    requestLinkSuccess,
    isRequestLinkEnabled,
  } = useSummaryHook({ location, player });

  return (
    <Wrapper>
      <ExportDataButton
        data={exportPlayer(player, location)}
        filename={`${player.player_name} Activate Scores`}
        onClick={onExportDataClick}
      >
        <strong>Export Data</strong>
      </ExportDataButton>
      <ResetMonthlyScoreButton onClick={onResetMonthlyScoreClick}>
        Reset Monthly Score
      </ResetMonthlyScoreButton>
      {isRequestLinkEnabled && (
        <RequestLinkButton
          onClick={onRequestLinkClick}
          disabled={isLoading || requestLinkSuccess}
        >
          {requestLinkSuccess ? "Request Sent ✅" : "Claim Profile"}
        </RequestLinkButton>
      )}
      <StarsWrapper>
        <Stars stars={player.stars} />
      </StarsWrapper>
      <TextInfo label="New Rank" value={<NewRank rank={newRank} />} />
      <TextInfo label="Old Rank" value={<Rank rank={player.player_rank} />} />

      <TextInfo label={`${location.name} Score`} value={player.local_score} />
      <TextInfo
        label={"Monthly Score"}
        value={`~ ${getMonthlyScore({ player, location })}`}
      />
      <TextInfo label="Leaderboard Rank" value={player.local_rank} />
      <TextInfo
        label="Levels"
        value={`${player.passedLevels}/${location.levels_count}`}
      />
      <TextInfo label="Top Scores" value={topScores} />
      <TextInfo label="Total Wins" value={timesPassedTotal} />
    </Wrapper>
  );
};
