import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  background: #222;
  padding: 20px;
  border-radius: 8px;
  width: 350px; /* Set fixed width */
  max-width: 90%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #333;
  color: white;
  width: 100%; /* Ensure input does not exceed modal width */
  box-sizing: border-box; /* Prevent width overflow */
`;

export const Button = styled.button<{ $cancel?: boolean }>`
  padding: 10px;
  background: ${({ $cancel }) => ($cancel ? "#dc3545" : "#007bff")};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background 0.3s ease;
  font-weight: bold;

  &:hover {
    background: ${({ $cancel }) => ($cancel ? "#c82333" : "#0056b3")};
  }
`;
