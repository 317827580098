import React from "react";
import { EditLocation } from "../EditLocation/component";
import { useLocationsHook } from "./hook";
import { ActionLink, Table, TableCell, TableHeader, TableRow } from "./styles";
import { LocationsTableProps } from "./types";

export const LocationsTable = React.memo(
  ({ locations }: LocationsTableProps): JSX.Element => {
    const {
      onDeleteClick,
      onDismissClick,
      onUpdateClick,
      isEditing,
      selectedLocation,
    } = useLocationsHook();

    return (
      <>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Name</TableHeader>
              <TableHeader>Actions</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {locations.map((location) => (
              <TableRow key={location._id}>
                <TableCell>{location.name}</TableCell>
                <TableCell>
                  <ActionLink onClick={onUpdateClick(location)}>
                    Update
                  </ActionLink>
                  <ActionLink onClick={onDeleteClick(location._id)} $danger>
                    Remove
                  </ActionLink>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
        {isEditing && selectedLocation && (
          <EditLocation location={selectedLocation} onClose={onDismissClick} />
        )}
      </>
    );
  }
);
