import "@/utils/featureFlag";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TopBar } from "./components/TopBar/component";
import { useActions } from "./hooks";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { fetchCurrentUser } from "./redux/reducers/auth/thunk";
import { fetchAllBadges } from "./redux/reducers/badges/thunk";
import { fetchLocations } from "./redux/reducers/locations/thunk";
import { adminRoutes, fallbackRoute, publicRoutes } from "./routes";
import { Wrapper } from "./styles";

export const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { fetchLastUpdated } = useActions();

  useEffect(() => {
    fetchLastUpdated();
    dispatch(fetchLocations());
    dispatch(fetchCurrentUser());
    dispatch(fetchAllBadges());
  }, [fetchLastUpdated, dispatch]);

  return (
    <Wrapper>
      <BrowserRouter>
        <TopBar />
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}

          {/* Admin routes */}
          <Route path={adminRoutes.path} element={adminRoutes.element}>
            {adminRoutes.children.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Route>

          {/* Fallback route */}
          <Route path={fallbackRoute.path} element={fallbackRoute.element} />
        </Routes>
      </BrowserRouter>
    </Wrapper>
  );
};
