import { useCallback, useEffect, useMemo, useState } from "react";
import { GRID_SIZE, LIVES, TIMER } from "../../constants";
import { Position } from "../../../../redux/responseTypes";
import { Tile } from "../Tile/component";
import { UseStrategyGameHookProps, UseStrategyGameHookResponse } from "./types";

export const useStrategyGameHook = ({
  strategyGame,
}: UseStrategyGameHookProps): UseStrategyGameHookResponse => {
  const initialState = useMemo(
    () => ({
      wave: 0,
      lives: LIVES,
      targets: (strategyGame && strategyGame.targets[0]) || { positions: [[]] },
      guns: (strategyGame && strategyGame.guns[0]) || [],
      redWave: 0,
      targetWave: 0,
      reds:
        (strategyGame.reds &&
          strategyGame.reds[0].positions &&
          strategyGame.reds![0].positions[0]) ||
        [],
      countdownTimer: TIMER,
      solutions:
        strategyGame && strategyGame.solutions ? strategyGame.solutions[0] : [],
    }),
    [strategyGame]
  );

  const [wave, setWave] = useState(initialState.wave);
  const [lives, setLives] = useState(initialState.lives);
  const [playWithReds, setPlayWithReds] = useState(true);
  // FIXME: This needs to be fixed
  const [targets, setTargets] = useState<Position[]>([]);
  const [guns, setGuns] = useState(initialState.guns);
  const [solutions, setSolutions] = useState(initialState.solutions);
  const [redWave, setRedWave] = useState(initialState.redWave);
  const [targetWave, setTargetWave] = useState(initialState.targetWave);
  const [reds, setReds] = useState<Position[]>(initialState.reds);
  const [targetsHit, setTargetsHit] = useState<number[]>([]);
  const [countdownTimer, setCountdownTimer] = useState(
    initialState.countdownTimer
  );
  const [pauseTimer, setPauseTimer] = useState(true);
  const [isTargetLoaded, setIsTargetLoaded] = useState(false);

  const resetGame = useCallback((): void => {
    setRedWave(initialState.redWave);
    playWithReds && setReds(initialState.reds);
    setWave(initialState.wave);
    setLives(initialState.lives);
    setTargetWave(initialState.targetWave);
    setTargetsHit([]);
    setCountdownTimer(initialState.countdownTimer);
    // setTargets([]);
    setGuns(initialState.guns);
    setSolutions(initialState.solutions);
  }, [initialState, playWithReds]);

  const resetWave = useCallback((): void => {
    const { guns, targets, solutions } = strategyGame;

    solutions && setSolutions(solutions[wave]);

    setTargetWave(targetWave);
    setTargets(targets[targetWave].positions[0]);
    setTargetsHit([]);
    setGuns(guns[wave]);

    setCountdownTimer(initialState.countdownTimer);
    setLives(initialState.lives);
  }, [
    strategyGame,
    wave,
    targetWave,
    initialState.countdownTimer,
    initialState.lives,
  ]);

  const getNextRedWave = useCallback(
    (positions: Position[][]): number => {
      return redWave + 1 >= positions.length ? 0 : redWave + 1;
    },
    [redWave]
  );

  const getNextTargetWave = useCallback(
    (positions: Position[][]): number => {
      return targetWave + 1 >= positions.length ? 0 : targetWave + 1;
    },
    [targetWave]
  );

  const triggerGun = useCallback(
    ({ x, y, direction }: Position): void => {
      if (!lives) return;

      // Remove gun from the grid
      setGuns((prevGuns) =>
        prevGuns.filter((gun) => !(gun.x === x && gun.y === y))
      );

      // Prevent out-of-bound directions
      if (
        (x === 0 && direction === "left") ||
        (y === 0 && direction === "up") ||
        (x === GRID_SIZE - 1 && direction === "right") ||
        (y === GRID_SIZE - 1 && direction === "down")
      ) {
        return;
      }

      // Helper function for movement logic
      const handleMovement = (
        start: number,
        end: number,
        step: number,
        getCoordinate: (i: number) => { x: number; y: number }
      ) => {
        for (let i = start; step > 0 ? i < end : i >= end; i += step) {
          const { x, y } = getCoordinate(i);

          // Check for red presence
          const redFound = reds.find((red) => red.x === x && red.y === y);
          if (redFound) {
            setLives((prevLives) => prevLives - 1);
            setReds((prevReds) =>
              prevReds.filter((red) => !(red.x === x && red.y === y))
            );
            return;
          }

          const { isDynamic = false } = strategyGame.targets[wave];

          // Check for target presence
          const targetFound = targets.find(
            (target) => target.x === x && target.y === y
          );
          if (targetFound) {
            setTargetsHit((prevTargetsHit) =>
              targetFound.id && !prevTargetsHit.includes(targetFound.id)
                ? [...prevTargetsHit, targetFound.id]
                : prevTargetsHit
            );

            setTargets((prevTargets) =>
              prevTargets.filter((target) => {
                if (isDynamic) {
                  return (
                    target.id &&
                    target.id !== targetFound.id &&
                    !targetsHit.includes(target.id)
                  );
                }

                return !(target.x === x && target.y === y);
              })
            );
            return;
          }
        }
      };

      // Get coordinate function based on direction
      const getCoordinateFunction = (direction: string) => {
        switch (direction) {
          case "right":
            return (i: number) => ({ x: i, y });
          case "left":
            return (i: number) => ({ x: i, y });
          case "down":
            return (i: number) => ({ x, y: i });
          case "up":
            return (i: number) => ({ x, y: i });
          default:
            return () => ({ x, y });
        }
      };

      // Define start, end, and step for each direction
      switch (direction) {
        case "right":
          handleMovement(x, GRID_SIZE, 1, getCoordinateFunction("right"));
          break;
        case "left":
          handleMovement(x, 0, -1, getCoordinateFunction("left"));
          break;
        case "down":
          handleMovement(y, GRID_SIZE, 1, getCoordinateFunction("down"));
          break;
        case "up":
          handleMovement(y, 0, -1, getCoordinateFunction("up"));
          break;
        default:
          break;
      }
    },
    [
      setGuns,
      lives,
      setLives,
      reds,
      setReds,
      targets,
      setTargets,
      targetsHit,
      setTargetsHit,
      strategyGame.targets,
      wave,
    ]
  );

  useEffect(() => {
    if (!isTargetLoaded) {
      setIsTargetLoaded(Boolean(targets.length));
    }
  }, [targets.length, isTargetLoaded]);

  useEffect(() => {
    resetGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyGame]);

  useEffect(() => {
    if (isTargetLoaded && targets.length === 0) {
      const nextWave = wave + 1 >= strategyGame.targets.length ? -1 : wave + 1;
      if (nextWave < 0) {
        return;
      }
      setTargets(strategyGame.targets[nextWave].positions[0]);
      setGuns(strategyGame.guns[nextWave]);
      setSolutions(
        (strategyGame.solutions && strategyGame.solutions[nextWave]) || []
      );
      setCountdownTimer(initialState.countdownTimer);
      setWave(nextWave);
      setIsTargetLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTargetLoaded, targets, countdownTimer, strategyGame]);

  useEffect(() => {
    const { config = { redSpeed: 1000 }, reds } = strategyGame;
    if (reds) {
      const { isDynamic, positions } = reds[wave];

      if (isDynamic) {
        const timer = window.setInterval(() => {
          const nextWave = getNextRedWave(positions);

          setRedWave(nextWave);
          setReds(
            playWithReds ? strategyGame.reds![wave].positions[redWave] : []
          );
        }, config.redSpeed);

        return () => window.clearInterval(timer);
      } else {
        if (positions) {
          setReds(playWithReds ? positions[0] : []);
        } else {
          setReds([]);
        }
      }
    } else {
      setReds([]);
    }
  }, [redWave, playWithReds, strategyGame, wave, getNextRedWave]);

  useEffect(() => {
    const { config = { targetSpeed: 1000 }, targets } = strategyGame;
    if (targets) {
      if (!targets[wave]) {
        return;
      }
      const { positions, isDynamic = false } = targets[wave];

      if (isDynamic) {
        const timer = window.setInterval(() => {
          const nextWave = getNextTargetWave(positions);

          setTargetWave(nextWave);
          setTargets(
            strategyGame.targets![wave].positions[targetWave].filter(
              (target) => target.id && !targetsHit.includes(target.id)
            )
          );
        }, config.redSpeed);

        return () => window.clearInterval(timer);
      } else {
        setTargets(positions[0]);
      }
    }
  }, [getNextTargetWave, strategyGame, targetWave, wave, targetsHit]);

  useEffect(() => {
    let timer: number;

    if (!pauseTimer) {
      if (countdownTimer !== 0 && isTargetLoaded && targets.length) {
        timer = window.setInterval(() => {
          setCountdownTimer(countdownTimer - 1);
        }, 1000);
      } else {
        targets.length && setLives(0);
      }
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTimer, isTargetLoaded, pauseTimer]);

  useEffect(() => {
    if (lives === 0) {
      setCountdownTimer(0);
    }
  }, [lives]);

  const gridCount = useMemo(
    () => Array.from({ length: GRID_SIZE }, (_, i) => i),
    []
  );

  const findTargetPosition = useCallback(
    ({ x, y }: Position): JSX.Element | null => {
      const pos = targets.find((target) => target.x === x && target.y === y);
      if (pos) {
        return <Tile color="target" />;
      }

      return null;
    },
    [targets]
  );

  const findSolutionOrder = useCallback(
    ({ x, y }: Position): string => {
      const solution = solutions.find(
        (solution) => solution.x === x && solution.y === y
      );
      if (solution) {
        return solution.order.toString();
      }
      return "";
    },
    [solutions]
  );

  const findGunPosition = useCallback(
    ({ x, y }: Position): JSX.Element | null => {
      const pos = guns.find((gun) => gun.x === x && gun.y === y);
      if (pos) {
        return (
          <Tile
            color="gun"
            onClick={() => {
              triggerGun(pos);
            }}
            content={findSolutionOrder({ x, y })}
          />
        );
      }
      return null;
    },
    [guns, triggerGun, findSolutionOrder]
  );

  const findRedPosition = useCallback(
    ({ x, y }: Position): JSX.Element | null => {
      if (!reds) {
        return null;
      }

      const pos = reds.find((red) => red.x === x && red.y === y);
      if (pos) {
        return <Tile color="red" />;
      }
      return null;
    },
    [reds]
  );

  return {
    lives,
    targets,
    wave,
    isTargetLoaded,
    countdownTimer,
    resetWave,
    resetGame,
    gridCount,
    playWithReds,
    setPlayWithReds,
    pauseTimer,
    setPauseTimer,
    findTargetPosition,
    findGunPosition,
    findRedPosition,
  };
};
