import styled from "styled-components";
import { TileWraperProps } from "./types";

export const TileWrapper = styled.div<TileWraperProps>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $color }) =>
    $color === "gun"
      ? "darkorange"
      : $color === "red"
      ? "red"
      : $color === "green"
      ? "green"
      : $color === "target"
      ? "blue"
      : "transparent"};
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};
`;

export const TileText = styled.span`
  font-size: 16px;
`;
