import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 16px;
  margin-bottom: 24px;
  cursor: pointer;
  &:hover {
    background-color: #ffffff22;
  }
  padding: 4px 8px;
  border-radius: 4px;
`;

export const Text = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`;
