import { Admin } from "./admin/pages/Admin";
import { ProtectedRoute } from "./components/ProtectedRoute";
import {
  Activate,
  LaserMaze,
  MiniTournament,
  PageNotFound,
  Strategy,
  TournamentOfChampions,
} from "./pages";

// Public routes
export const publicRoutes = [
  { path: "/", element: <Activate /> },
  { path: "/toc", element: <TournamentOfChampions /> },
  { path: "/mini-tournament", element: <MiniTournament /> },
  { path: "/maze", element: <LaserMaze /> },
  { path: "/strategy/:level", element: <Strategy /> },
];

// Admin routes (wrapped in `ProtectedRoute`)
export const adminRoutes = {
  path: "/admin",
  element: <ProtectedRoute />,
  children: [
    { path: "", element: <Admin /> },
    // { path: "locations", element: <Locations /> },
  ],
};

// Catch-all route
export const fallbackRoute = { path: "*", element: <PageNotFound /> };
