import { useLoadingDivHook } from "./hook";
import { Placeholder, Spinner, Wrapper } from "./styles";

export const LoadingDiv = (): JSX.Element => {
  const { randomPlaceholder } = useLoadingDivHook();

  return (
    <Wrapper>
      <Spinner />
      <Placeholder>{randomPlaceholder}</Placeholder>
    </Wrapper>
  );
};
