import { v4 as uuidv4 } from "uuid";

import { GAME_TITLES, PLAYERS } from "./constants";
import {
  GameTitle,
  OverallScore,
  PlayerScore,
  UseTournamentOfChampionsHookResponse,
} from "./types";
import { useCallback, useMemo } from "react";

export const useTournamentOfChampionsHook =
  (): UseTournamentOfChampionsHookResponse => {
    const getHiddenName = useCallback(
      (index: number): string => `Anonymous #${index + 1}`,
      []
    );

    const displayName = useCallback(
      (player: PlayerScore, hiddenIndex: number): string => {
        return player.hidden ? getHiddenName(hiddenIndex) : player.name;
      },
      [getHiddenName]
    );

    const generateRandomScores = useCallback((): PlayerScore[] => {
      return PLAYERS.map<PlayerScore>((player) => ({
        id: uuidv4(),
        name: player,
        scores: GAME_TITLES.reduce((acc, game) => {
          acc[game] = Math.floor(Math.random() * 1000);
          return acc;
        }, {} as Record<GameTitle, number>),
        hidden: Math.random() < 0.3,
      }));
    }, []);

    const playerData = useMemo(
      () => generateRandomScores(),
      [generateRandomScores]
    );

    const overallScores = useMemo(
      () =>
        playerData.map<OverallScore>((player) => ({
          player,
          totalScore: Object.values(player.scores).reduce(
            (acc: any, score) => acc + score,
            0
          ),
        })),
      [playerData]
    );

    const sortedOverallScores = useMemo(
      () => [...overallScores].sort((a, b) => b.totalScore - a.totalScore),
      [overallScores]
    );

    return { sortedOverallScores, displayName, playerData };
  };
