import React from "react";
import { TextInfoProps } from "./types";
import { Text, TextWrapper, Wrapper } from "./styles";

export const TextInfo = React.memo(
  ({ label, value }: TextInfoProps): JSX.Element => {
    return (
      <Wrapper>
        <TextWrapper>
          <Text>{label}</Text>
          <Text>{value}</Text>
        </TextWrapper>
      </Wrapper>
    );
  }
);
