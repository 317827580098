import { useCallback, useState } from "react";
import {
  useActions,
  useAnalyticsEventTracker,
  useTypedSelector,
} from "../../../../hooks";
import { UseBoundPlayersHookResponse } from "./types";
import {
  getLocalStorageObject,
  setLocalStorageObject,
} from "../../../../utils";
import { BINDED_PLAYERS_LOCAL_STORAGE_KEY } from "../../constants";

export const useBoundPlayersHook = (): UseBoundPlayersHookResponse => {
  const { isApiDown } = useTypedSelector(({ lastUpdated }) => lastUpdated);
  const { eventTracker } = useAnalyticsEventTracker("bound players");
  const { fetchPlayerInformation } = useActions();
  const [boundPlayers, setBoundPlayers] = useState(
    getLocalStorageObject(BINDED_PLAYERS_LOCAL_STORAGE_KEY)
  );

  const onTextChange = useCallback(
    (key: string) =>
      (e: React.ChangeEvent<HTMLInputElement>): void => {
        setBoundPlayers({ ...boundPlayers, [key]: e.target.value });
      },
    [setBoundPlayers, boundPlayers]
  );

  const saveBoundPlayersOnBlur = useCallback((): void => {
    if (!boundPlayers) {
      return;
    }

    eventTracker("Updated list of bound players");
    setLocalStorageObject(BINDED_PLAYERS_LOCAL_STORAGE_KEY, boundPlayers);
  }, [eventTracker, boundPlayers]);

  const onGoButtonClick = useCallback(
    (username: string) => (): void => {
      fetchPlayerInformation(username);
    },
    [fetchPlayerInformation]
  );

  return {
    boundPlayers,
    isApiDown,
    onTextChange,
    saveBoundPlayersOnBlur,
    onGoButtonClick,
  };
};
