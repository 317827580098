import styled from "styled-components";
import { ButtonProps } from "./types";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
`;

export const Input = styled.input`
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
`;

export const Button = styled.button<ButtonProps>`
  padding: 8px 12px;
  font-size: 16px;
  color: white;
  background-color: ${({ $cancel }) => ($cancel ? "#dc3545" : "#007bff")};
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ $cancel }) => ($cancel ? "#b22222" : "#0056b3")};
  }
`;
