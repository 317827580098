import { CheckboxProps } from "./types";
import { useCheckboxHook } from "./hook";
import { CheckboxInput, Label, NewTagIcon, Wrapper } from "./styles";
import React from "react";

export const Checkbox = React.memo(
  ({
    label,
    value,
    onValueChange,
    newTag = false,
    additionalStyles,
    "data-trackid": dataTrackId,
  }: CheckboxProps): JSX.Element => {
    const { handleOnValueChange } = useCheckboxHook({
      onValueChange,
      value,
      dataTrackId,
    });

    return (
      <Wrapper style={additionalStyles}>
        <CheckboxInput
          id={label}
          checked={value}
          onChange={handleOnValueChange}
        />
        <Label htmlFor={label}>{label}</Label>
        {newTag && <NewTagIcon />}
      </Wrapper>
    );
  }
);
