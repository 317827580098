import { useAppDispatch } from "@/hooks/useAppDispatch";
import { updateLocation } from "@/redux/reducers/locations/thunk";
import { useCallback, useState } from "react";
import { UseEditLocationHookProps, UseEditLocationHookResponse } from "./types";

export const useEditLocationHook = ({
  location,
  onClose,
}: UseEditLocationHookProps): UseEditLocationHookResponse => {
  const [locationName, setLocationName] = useState(location.name);
  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    (e: React.FormEvent): void => {
      e.preventDefault();

      if (!locationName.trim()) {
        return;
      }

      dispatch(updateLocation({ _id: location._id, name: locationName }));
      onClose();
    },
    [locationName, dispatch, location._id, onClose]
  );

  const onLocationNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLocationName(e.target.value);
  };

  return {
    onSubmit,
    locationName,
    onLocationNameChange,
  };
};
