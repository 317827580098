import { Option } from "@/components/DropdownSelect";
import { getOptionByLabel } from "@/components/DropdownSelect/utils";
import { RadioItemValue } from "@/components/RadioGroup/types";
import {
  useActions,
  useAnalyticsEventTracker,
  useOtherLocations,
  usePlayerInfo,
} from "@/hooks";
import { useAppSelector } from "@/hooks/useAppSelector";
import { selectLocationsState } from "@/redux/reducers/locations/reducer";
import { useCallback, useMemo, useState } from "react";
import { UseRoomHookProps, UseRoomHookResponse } from "./types";

export const useRoomHook = ({
  location,
}: UseRoomHookProps): UseRoomHookResponse => {
  const { locations } = useAppSelector(selectLocationsState);
  const locationOptions: Option[] = useMemo(
    () =>
      (locations &&
        locations.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }))) ||
      [],
    [locations]
  );

  const [selectedOption, setSelectedOption] = useState<Option>(
    getOptionByLabel(location, locationOptions)
  );

  const [improveScoreBy, setImproveScoreBy] = useState<RadioItemValue>(200);

  const { comparePlayers } = usePlayerInfo();
  const { eventTracker } = useAnalyticsEventTracker("Room");
  const { fetchListPlayers } = useActions();
  const { isComparingWithOtherLocations } = useOtherLocations();

  const handleImproveScoreBy = useCallback(
    (value: RadioItemValue) => {
      eventTracker(`improve score by ${value}`);
      setImproveScoreBy(value);
    },
    [eventTracker]
  );

  const handleOnSetSelectedOption = useCallback((option: Option) => {
    setSelectedOption(option);
  }, []);

  const isComparing = useMemo(
    () => isComparingWithOtherLocations || comparePlayers.length > 0,
    [isComparingWithOtherLocations, comparePlayers.length]
  );

  const handleOnSearchClick = useCallback(
    (playerNames: string) => {
      eventTracker("compare players");
      fetchListPlayers(playerNames, selectedOption.label);
    },
    [selectedOption.label, eventTracker, fetchListPlayers]
  );

  return {
    handleImproveScoreBy,
    handleOnSearchClick,
    handleOnSetSelectedOption,
    improveScoreBy,
    isComparing,
    isComparingWithOtherLocations,
    selectedOption,
    locationOptions,
  };
};
