import styled from "styled-components";
import { CircleProps, DotProps } from "./types";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(18, 30px);
  grid-template-rows: repeat(10, 30px);
  gap: 10px;
  margin: 0 auto;
`;

export const Circle = styled.div<CircleProps>`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #00ff00;
  background-color: ${({ $isLitUp }) => ($isLitUp ? "#00ff00" : "transparent")};
`;

export const Dot = styled.div<DotProps>`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ $isLitUp }) => ($isLitUp ? "#00ff00" : "transparent")};
`;
