import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 24px 0;
  padding: 0 100px;
`;

export const Batch = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0; /* No margin for the second batch */
  }
`;
