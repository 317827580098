import { SearchInput } from "@/components";
import React from "react";
import { useSearchPlayerHook } from "./hook";
import { Wrapper } from "./styles";

export const SearchPlayer = React.memo((): JSX.Element => {
  const { errorMessage, handleSearchClick, isApiDown } = useSearchPlayerHook();

  return (
    <Wrapper>
      <SearchInput
        onSearchClick={handleSearchClick}
        placeholder="Enter profile name or bound key"
        error={errorMessage}
        focusOnRender
        disabled={isApiDown}
      />
    </Wrapper>
  );
});
