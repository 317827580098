import React from "react";
import { useAddLocationHook } from "./hook";
import { Button, FormContainer, Input } from "./styles";

export const AddLocation = React.memo((): JSX.Element => {
  const { locationName, onAddLocationClick, onInputNameChange } =
    useAddLocationHook();

  return (
    <FormContainer>
      <Input
        type="text"
        value={locationName}
        onChange={onInputNameChange}
        placeholder="Enter location name"
      />
      <Button onClick={onAddLocationClick}>Add</Button>
    </FormContainer>
  );
});
