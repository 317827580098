import React, { Fragment } from "react";
import { ScoreDifference } from "../ScoreDifference/component";
import { TotalScorePerGame } from "..";
import {
  isPersonalRecord,
  getScoreByGame,
  getTimesPassedLevel,
} from "../../../../utils";
import { isGameLevelOnMonthlyScore } from "../../../../../../utils/monthlyScore";
import { PersonalRecordCell, MonthlyScoreRow } from "./styles";
import { LevelRowsProps } from "./types";
import { useLevelRowsHook } from "./hook";

export const LevelRows = React.memo(
  ({ levels, showScoreDifference }: LevelRowsProps): JSX.Element => {
    const {
      isComparingWithOtherPlayers,
      location,
      onScoreRowClick,
      player,
      allPlayers,
      isComparingOldScores,
      playerYesterday,
    } = useLevelRowsHook();

    return (
      <>
        {levels.map((level) => {
          let hasPersonalRecord = false;
          const RowComponent =
            !isComparingWithOtherPlayers &&
            isGameLevelOnMonthlyScore({
              player,
              location,
              gameId: level.game_id,
              levelId: level.level_id,
            })
              ? MonthlyScoreRow
              : "tr";

          return (
            <RowComponent
              key={`level-${level.id}`}
              onClick={() =>
                !isComparingWithOtherPlayers &&
                onScoreRowClick(level.game_id, level.level_id)
              }
            >
              <td>{level.level_id + 1}</td>
              {allPlayers.map((player, i) => {
                const currentScore = getScoreByGame(
                  player,
                  level.game_id,
                  level.level_id
                );
                const yesterdayScore = getScoreByGame(
                  playerYesterday || null,
                  level.game_id,
                  level.level_id
                );

                const timesPassed = getTimesPassedLevel(
                  player,
                  level.game_id,
                  level.level_id
                );
                const yesterdayTimesPassed = getTimesPassedLevel(
                  playerYesterday || null,
                  level.game_id,
                  level.level_id
                );

                if (!hasPersonalRecord) {
                  hasPersonalRecord = isPersonalRecord(
                    currentScore,
                    level.top_score
                  );
                }

                return (
                  <Fragment key={`score-${i}`}>
                    {!isComparingWithOtherPlayers && (
                      <td>
                        {timesPassed}
                        {isComparingOldScores && (
                          <ScoreDifference
                            score={yesterdayTimesPassed}
                            topScore={timesPassed}
                            visible
                            positiveSign
                          />
                        )}
                      </td>
                    )}
                    {isPersonalRecord(currentScore, level.top_score) ? (
                      <PersonalRecordCell>
                        {currentScore}
                        {isComparingOldScores ? (
                          <ScoreDifference
                            score={yesterdayScore}
                            topScore={currentScore}
                            visible
                            positiveSign
                          />
                        ) : (
                          <ScoreDifference
                            score={currentScore}
                            topScore={level.top_score}
                            visible={showScoreDifference}
                          />
                        )}
                      </PersonalRecordCell>
                    ) : (
                      <td>
                        {currentScore}
                        {isComparingOldScores ? (
                          <ScoreDifference
                            score={yesterdayScore}
                            topScore={currentScore}
                            visible
                            positiveSign
                          />
                        ) : (
                          <ScoreDifference
                            score={currentScore}
                            topScore={level.top_score}
                            visible={showScoreDifference}
                          />
                        )}
                      </td>
                    )}
                  </Fragment>
                );
              })}
              {hasPersonalRecord ? (
                <PersonalRecordCell>{level.top_score || 0}</PersonalRecordCell>
              ) : (
                <td>{level.top_score || 0}</td>
              )}
            </RowComponent>
          );
        })}
        <TotalScorePerGame levels={levels} players={allPlayers} />
      </>
    );
  }
);
