import { useMemo } from "react";
import {
  useOtherLocations,
  usePlayerInfo,
  useTypedSelector,
} from "../../../../../../hooks";
import { getKeyIdForTopScore, getScoreByGame } from "../../../../utils";
import {
  UseTotalScoresPerGameHookProps,
  UseTotalScoresPerGameHookResponse,
} from "./types";

export const useTotalScoresPerGameHook = ({
  levels,
  players,
  topScoresAllLocations,
}: UseTotalScoresPerGameHookProps): UseTotalScoresPerGameHookResponse => {
  const { isComparingWithOtherLocations } = useOtherLocations();
  const { showTotalScorePerGame, showScoreDifference } = useTypedSelector(
    ({ filterScores }) => filterScores
  );
  const { comparePlayers } = usePlayerInfo();

  const totalScoresPerPlayer = useMemo(
    () =>
      players.map((player) =>
        levels.reduce(
          (score, level) =>
            score + getScoreByGame(player, level.game_id, level.level_id),
          0
        )
      ),
    [players, levels]
  );

  const totalTopScores = useMemo(
    () =>
      levels.reduce((acc, { game_id, level_id, top_score }) => {
        const key = getKeyIdForTopScore({ game_id, level_id });
        return (
          acc + (topScoresAllLocations ? topScoresAllLocations[key] : top_score)
        );
      }, 0),
    [levels, topScoresAllLocations]
  );

  const isComparing = useMemo(
    () => isComparingWithOtherLocations || comparePlayers.length > 0,
    [isComparingWithOtherLocations, comparePlayers.length]
  );

  return {
    isComparing,
    showScoreDifference,
    showTotalScorePerGame,
    totalScoresPerPlayer,
    totalTopScores,
  };
};
