import styled from "styled-components";
import { RowProps } from "./types";

export const Menu = styled.ul`
  text-align: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 20%;
  background-color: rgba(0, 0, 0, 0.3);
  li {
    cursor: pointer;
    padding: 8px;
    color: white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      color: #ffffffcc;
    }
  }
  height: fit-content;
`;

export const SubMenu = styled.ul`
  text-align: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  li {
    padding-left: 48px;
  }
`;

export const Row = styled.li<RowProps>`
  background-color: ${(props) =>
    props.$isSelected && "rgba(255, 255, 255, 0.3) !important"};
  color: ${(props) => props.$isSelected && "#ffffff99 !important"};
`;
