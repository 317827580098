import { useAppDispatch } from "@/hooks/useAppDispatch";
import { addBadge } from "@/redux/reducers/badges/thunk";
import React, { useMemo, useState } from "react";
import { Button, FormContainer, Input } from "./styles";

export const AddBadge = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    if (!title || !description) return;
    dispatch(addBadge({ title, description }));
    setTitle("");
    setDescription("");
  };

  const isDisabled = useMemo(
    () => title === "" || description === "",
    [title, description]
  );

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Input
        type="text"
        placeholder="Badge Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Input
        type="text"
        placeholder="Badge Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button type="submit" disabled={isDisabled}>
        Add Badge
      </Button>
    </FormContainer>
  );
};
