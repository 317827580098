import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 1.875rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  margin-left: 24px;
`;

export const Text = styled.div`
  width: 33%;
  text-align: left;
  margin-bottom: 24px;
`;
