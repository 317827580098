import React from "react";
import { ScoreDifference } from "../ScoreDifference/component";
import { isPersonalRecord } from "../../../../utils";
import { PersonalRecordCell, TotalRow } from "./styles";
import { TotalScorePerGameProps } from "./types";
import { useTotalScoresPerGameHook } from "./hook";

export const TotalScorePerGame = React.memo(
  ({
    levels,
    players,
    forceShow = false,
    topScoresAllLocations,
  }: TotalScorePerGameProps): JSX.Element => {
    const {
      isComparing,
      showScoreDifference,
      showTotalScorePerGame,
      totalScoresPerPlayer,
      totalTopScores,
    } = useTotalScoresPerGameHook({
      levels,
      players,
      topScoresAllLocations,
    });

    if (!showTotalScorePerGame && !forceShow) {
      return <></>;
    }

    return (
      <TotalRow>
        <th colSpan={isComparing ? 1 : 2}>Total</th>
        {totalScoresPerPlayer.map((score, i) => {
          const hasPersonalRecord =
            totalTopScores && isPersonalRecord(score, totalTopScores);
          const CellComponent = hasPersonalRecord ? PersonalRecordCell : "td";

          return (
            <CellComponent key={i}>
              {score}
              <ScoreDifference
                score={score}
                topScore={totalTopScores}
                visible={showScoreDifference}
              />
            </CellComponent>
          );
        })}
        {totalTopScores ? (
          <PersonalRecordCell>{totalTopScores}</PersonalRecordCell>
        ) : (
          <td>{totalTopScores}</td>
        )}
      </TotalRow>
    );
  }
);
