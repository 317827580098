import React from "react";
import { RemoveX } from "../RemoveX";
import { List, ListItem } from "./styles";
import { useListPlayersToRemoveHook } from "./hook";

export const ListPlayersToRemove = React.memo((): JSX.Element => {
  const { comparePlayers, removePlayerListPlayers } =
    useListPlayersToRemoveHook();

  return (
    <List>
      {comparePlayers.map(({ player_name }, i) => {
        const handleOnClick = () => removePlayerListPlayers(player_name);

        return (
          <ListItem key={i} onClick={handleOnClick}>
            <RemoveX onClick={handleOnClick} /> {player_name}
          </ListItem>
        );
      })}
    </List>
  );
});
