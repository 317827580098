import React from "react";
import { Bold, Wrapper } from "./styles";

export const NewTag = React.memo((): JSX.Element => {
  return (
    <Wrapper>
      <Bold>NEW</Bold>
    </Wrapper>
  );
});
