import { SearchInput, Title } from "@/components";
import { Banner } from "@/components/Banner/component";
import { DropdownSelect } from "@/components/DropdownSelect";
import React from "react";
import { Filters } from "..";
import { ShowGameScores } from "./components";
import { ListPlayersToRemove } from "./components/ListPlayersToRemove/component";
import { useRoomHook } from "./hook";
import {
  ListPlayersContainer,
  MagentaSquare,
  SearchInputWrapper,
  SearchWrapper,
} from "./styles";
import { RoomProps } from "./types";

export const Room = React.memo(({ room, location }: RoomProps): JSX.Element => {
  const {
    handleImproveScoreBy,
    handleOnSearchClick,
    handleOnSetSelectedOption,
    improveScoreBy,
    isComparing,
    isComparingWithOtherLocations,
    selectedOption,
    locationOptions,
  } = useRoomHook({ location });

  if (!room?.name) {
    return <>Game not found</>;
  }

  return (
    <>
      <Banner
        bannerType="alert"
        message="Disclaimer - Game Scores are no longer reliable"
      />

      <SearchWrapper>
        <div>
          <Filters
            improveScoreBy={improveScoreBy}
            handleImproveScoreBy={handleImproveScoreBy}
          />
          {!isComparing && (
            <p>
              <MagentaSquare />
              Monthly Score
            </p>
          )}
        </div>
        {!isComparingWithOtherLocations && (
          <SearchInputWrapper>
            <SearchInput
              buttonLabel="Compare"
              onSearchClick={handleOnSearchClick}
              size="small"
              placeholder="Profile name to compare"
            />
            <DropdownSelect
              options={locationOptions}
              selectedOption={selectedOption}
              setSelectedOption={handleOnSetSelectedOption}
            />
            <ListPlayersContainer>
              <ListPlayersToRemove />
            </ListPlayersContainer>
          </SearchInputWrapper>
        )}
      </SearchWrapper>
      <Title additionalStyles={{ textTransform: "uppercase" }}>
        {room.name}
      </Title>
      <ShowGameScores games={room.games} improveScoreBy={improveScoreBy} />
    </>
  );
});
