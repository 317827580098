import { useAppDispatch } from "@/hooks/useAppDispatch";
import { useAppSelector } from "@/hooks/useAppSelector";
import { selectAuthState } from "@/redux/reducers/auth/reducer";
import { selectPlayerLinksState } from "@/redux/reducers/playerLinks/reducer";
import {
  checkPlayerLinkStatus,
  requestPlayerLink,
} from "@/redux/reducers/playerLinks/thunk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useActions, useAnalyticsEventTracker } from "../../../../hooks";
import { resetMonthlyScore } from "../../../../utils/monthlyScore";
import {
  getScoreByGame,
  getTimesPassedLevel,
  isPersonalRecord,
} from "../../utils";
import { UseSummaryHookProps, UseSummaryHookResponse } from "./types";

export const useSummaryHook = ({
  location,
  player,
}: UseSummaryHookProps): UseSummaryHookResponse => {
  const dispatch = useAppDispatch();
  const { user, isAuthenticated } = useAppSelector(selectAuthState);
  const { eventTracker } = useAnalyticsEventTracker("Account Summary");
  const { fakeAPICall } = useActions();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { isLoading, requestLinkSuccess, canRequest } = useAppSelector(
    selectPlayerLinksState
  );

  useEffect(() => {
    if (player.player_name) {
      isAuthenticated &&
        dispatch(checkPlayerLinkStatus({ playerName: player.player_name }));
    }
  }, [dispatch, player, isAuthenticated]);

  const isRequestLinkEnabled = useMemo(
    () => Boolean(user) && user?.playerName === undefined && canRequest,
    [user, canRequest]
  );

  const onRequestLinkClick = useCallback((): void => {
    dispatch(
      requestPlayerLink({ playerName: player.player_name, email: user?.email })
    );
  }, [dispatch, player, user]);

  const { topScores, timesPassedTotal } = useMemo(() => {
    let calculatedTopScores = 0;
    let calculatedTimesPassedTotal = 0;

    location.rooms.forEach(({ games }) =>
      games.forEach(({ levels }) =>
        levels.forEach(({ game_id, level_id, top_score }) => {
          const score = getScoreByGame(player, game_id, level_id);
          calculatedTopScores += isPersonalRecord(score, top_score) ? 1 : 0;
          calculatedTimesPassedTotal += getTimesPassedLevel(
            player,
            game_id,
            level_id
          );
        })
      )
    );

    return {
      topScores: calculatedTopScores,
      timesPassedTotal: calculatedTimesPassedTotal,
    };
  }, [player, location]);

  const newRank = useMemo(
    () => Math.floor(player.local_score / 25000),
    [player.local_score]
  );

  const onExportDataClick = useCallback(() => {
    fakeAPICall(`/players/exportData/${player.player_name}`);
    eventTracker(`Export Data (${player.player_name})`);
  }, [fakeAPICall, eventTracker, player.player_name]);

  const onResetMonthlyScoreClick = useCallback((): void => {
    resetMonthlyScore({ player, location });
    setForceRefresh(!forceRefresh);
  }, [player, location, setForceRefresh, forceRefresh]);

  return {
    onExportDataClick,
    onResetMonthlyScoreClick,
    newRank,
    timesPassedTotal,
    topScores,
    onRequestLinkClick,
    isLoading,
    requestLinkSuccess,
    isRequestLinkEnabled,
  };
};
