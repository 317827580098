import { useCallback, useMemo } from "react";
import { useOtherLocations, useTypedSelector } from "../../../../../../hooks";
import { Game, Level } from "../../../../../../redux/models";
import { getScoreByGame, isPersonalRecord } from "../../../../utils";
import { UseGamesListHookProps, UseGamesListHookResponse } from "./types";

export const useGamesListHook = ({
  improveScoreBy,
}: UseGamesListHookProps): UseGamesListHookResponse => {
  const {
    playerInfo: { player, comparePlayers },
    filterScores: {
      showOnlyDifferentLevels,
      showOnlyLevelsToMatchScore,
      showOnlyLevelsToImprove,
      showOnlyMissingLevels,
      showOnlyTopScores,
      showOnlyNotTopScores,
      showScoreDifference,
    },
  } = useTypedSelector(({ playerInfo, filterScores }) => ({
    playerInfo,
    filterScores,
  }));

  const { isComparingWithOtherLocations } = useOtherLocations();

  const allPlayers = useMemo(
    () => [player, ...comparePlayers],
    [player, comparePlayers]
  );
  const isComparingWithOtherPlayers = useMemo(
    () => allPlayers.length > 1,
    [allPlayers]
  );

  const filterTopScores = useCallback(
    (levels: Level[]): Level[] =>
      levels.filter((level) =>
        isPersonalRecord(
          getScoreByGame(player, level.game_id, level.level_id),
          level.top_score
        )
      ),
    [player]
  );

  const filterNotTopScores = useCallback(
    (levels: Level[]): Level[] =>
      levels.filter(
        (level) =>
          !isPersonalRecord(
            getScoreByGame(player, level.game_id, level.level_id),
            level.top_score
          )
      ),
    [player]
  );

  const filterMissingLevels = useCallback(
    (levels: Level[]): Level[] =>
      levels.filter(
        (level) => !getScoreByGame(player, level.game_id, level.level_id)
      ),
    [player]
  );

  const filterLevelsToImproveScore = useCallback(
    (levels: Level[]): Level[] =>
      levels.filter((level) => {
        const playerScore = getScoreByGame(
          player,
          level.game_id,
          level.level_id
        );
        return (
          playerScore &&
          typeof improveScoreBy === "number" &&
          level.top_score - playerScore >= improveScoreBy
        );
      }),
    [player, improveScoreBy]
  );

  const filterDifferentLevels = useCallback(
    (levels: Level[]): Level[] =>
      levels.filter((level) => {
        const playerScore = getScoreByGame(
          player,
          level.game_id,
          level.level_id
        );
        return comparePlayers.some(
          (comparePlayer) =>
            (playerScore &&
              !getScoreByGame(comparePlayer, level.game_id, level.level_id)) ||
            (!playerScore &&
              getScoreByGame(comparePlayer, level.game_id, level.level_id))
        );
      }),
    [player, comparePlayers]
  );

  const filterLevelsToMatch = useCallback(
    (levels: Level[]): Level[] =>
      levels.filter((level) => {
        const playerScore = getScoreByGame(
          player,
          level.game_id,
          level.level_id
        );
        return comparePlayers.some(
          (comparePlayer) =>
            playerScore !==
            getScoreByGame(comparePlayer, level.game_id, level.level_id)
        );
      }),
    [player, comparePlayers]
  );

  const filterGameLevels = useCallback(
    ({ levels }: Game): Level[] => {
      if (!comparePlayers.length) {
        if (showOnlyLevelsToImprove) return filterLevelsToImproveScore(levels);
        if (showOnlyMissingLevels) return filterMissingLevels(levels);
        if (showOnlyTopScores) return filterTopScores(levels);
        if (showOnlyNotTopScores) return filterNotTopScores(levels);
        return levels;
      }

      if (showOnlyDifferentLevels) return filterDifferentLevels(levels);
      if (showOnlyLevelsToMatchScore) return filterLevelsToMatch(levels);
      return levels;
    },
    [
      comparePlayers.length,
      showOnlyLevelsToImprove,
      showOnlyMissingLevels,
      showOnlyTopScores,
      showOnlyNotTopScores,
      showOnlyDifferentLevels,
      showOnlyLevelsToMatchScore,
      filterLevelsToImproveScore,
      filterMissingLevels,
      filterTopScores,
      filterNotTopScores,
      filterDifferentLevels,
      filterLevelsToMatch,
    ]
  );

  return useMemo(
    () => ({
      isComparingWithOtherLocations,
      filterGameLevels,
      allPlayers,
      isComparingWithOtherPlayers,
      showScoreDifference,
    }),
    [
      isComparingWithOtherLocations,
      filterGameLevels,
      allPlayers,
      isComparingWithOtherPlayers,
      showScoreDifference,
    ]
  );
};
