import { UseStopPropagationResponse } from "./types";

export const useStopPropagation = (): UseStopPropagationResponse => {
  const stopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    e.stopPropagation();
  };

  return { stopPropagation };
};
