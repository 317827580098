import styled from "styled-components";

export const ActionLink = styled.a`
  margin-right: 12px;
  color: #ff5252;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
