import { useCallback, useMemo, useState } from "react";
import {
  MenuActive,
  UseSideMenuHookProps,
  UseSideMenuHookResponse,
} from "./types";
import {
  useActions,
  useCompareOldScores,
  useOtherLocations,
  useTypedSelector,
} from "../../../../hooks";

export const useSideMenuHook = ({
  menuActive,
  setMenuActive,
}: UseSideMenuHookProps): UseSideMenuHookResponse => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    playerInfo: {
      locations,
      location,
      comparePlayers,
      player: { topScores, notTopScores, missingLevels },
    },
    filterScores: {
      showOnlyTopScores,
      showOnlyNotTopScores,
      showOnlyMissingLevels,
    },
  } = useTypedSelector(({ playerInfo, filterScores }) => ({
    playerInfo,
    filterScores,
  }));
  const { isComparingWithOtherLocations } = useOtherLocations();
  const { isComparingOldScores } = useCompareOldScores();
  const {
    clearPlayerAllLocations,
    fetchPlayerAllLocations,
    fetchPlayerYesterday,
    clearPlayerYesterday,
  } = useActions();

  const isMenuActive = useCallback(
    (menuOption: MenuActive): boolean => {
      return menuActive === menuOption;
    },
    [menuActive]
  );

  const onMenuChange = useCallback(
    (menu: MenuActive): void => {
      setMenuActive(menu);
    },
    [setMenuActive]
  );

  const getFilterCountPerRoom = useCallback(
    (id: number): string | null => {
      if (!comparePlayers.length) {
        let filterCount: string | null = null;
        if (showOnlyTopScores) {
          filterCount = (topScores[id] && `(${topScores[id]})`) || null;
        } else if (showOnlyNotTopScores) {
          filterCount = (notTopScores[id] && `(${notTopScores[id]})`) || null;
        } else if (showOnlyMissingLevels) {
          filterCount = (missingLevels[id] && `(${missingLevels[id]})`) || null;
        }

        return filterCount;
      }

      return null;
    },
    [
      comparePlayers,
      showOnlyTopScores,
      showOnlyNotTopScores,
      topScores,
      notTopScores,
      showOnlyMissingLevels,
      missingLevels,
    ]
  );

  const hasMoreThanOneLocation = useMemo(
    () => locations.length > 1,
    [locations]
  );

  const onSwitchLocationClick = useCallback(
    () => setIsModalOpen(true),
    [setIsModalOpen]
  );

  return {
    isMenuActive,
    onMenuChange,
    location,
    getFilterCountPerRoom,
    isComparingWithOtherLocations,
    isComparingOldScores,
    clearPlayerYesterday,
    clearPlayerAllLocations,
    fetchPlayerAllLocations,
    fetchPlayerYesterday,
    hasMoreThanOneLocation,
    onSwitchLocationClick,
    isModalOpen,
    setIsModalOpen,
  };
};
