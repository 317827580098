import { useAppDispatch } from "@//hooks/useAppDispatch";
import { useActions, useTypedSelector } from "@/hooks";
import {
  sortBadgesByStars,
  sortBadgesByTitle,
} from "@/redux/reducers/badge/reducer";
import { fetchBadges } from "@/redux/reducers/badge/thunks";
import { getLocalStorageObject, setLocalStorageObject } from "@/utils";
import { saveAs } from "file-saver";
import { useCallback, useEffect, useRef, useState } from "react";
import { BADGES_LOCAL_STORAGE_KEY } from "./constants";
import { UseBadgesHookProps, UseBadgesHookResponse } from "./types";

export const useBadgesHook = ({
  playerName,
}: UseBadgesHookProps): UseBadgesHookResponse => {
  const dispatch = useAppDispatch();
  const LOCAL_STORAGE_KEY = `${BADGES_LOCAL_STORAGE_KEY}_${playerName}`;

  const [badgesLocalStorage, setBadgesLocalStorage] = useState(
    getLocalStorageObject(LOCAL_STORAGE_KEY)
  );

  useEffect(() => {
    dispatch(fetchBadges());
  }, [dispatch]);

  useEffect(() => {
    setLocalStorageObject(BADGES_LOCAL_STORAGE_KEY, badgesLocalStorage || {});
  }, [badgesLocalStorage, LOCAL_STORAGE_KEY]);

  const tableRef = useRef<HTMLDivElement>(null);
  const [isDisplayRiddleSolution, setIsDisplayRiddleSolution] = useState(false);
  const [filterCompleteBadges, setFilterCompleteBadges] = useState(false);
  const [filterIncompleteBadges, setFilterIncompleteBadges] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [importBadgesJSON, setImportBadgesJSON] = useState("");

  const { fakeAPICall } = useActions();
  const { isLoading, badges, sortByTitles, sortByStars } = useTypedSelector(
    (state) => state.badge
  );

  const onSortBadgesByTitleClick = useCallback((): void => {
    dispatch(sortBadgesByTitle());
  }, [dispatch]);

  const onSortBadgesByStarsClick = useCallback((): void => {
    dispatch(sortBadgesByStars());
  }, [dispatch]);

  const hasBadge = useCallback(
    (title: string): boolean => {
      return Boolean((badgesLocalStorage || {})[title]);
    },
    [badgesLocalStorage]
  );

  const onBadgeRowClick = useCallback(
    (title: string) => () => {
      const _badgesLocalStorage = { ...badgesLocalStorage };
      if (hasBadge(title)) {
        delete _badgesLocalStorage[title];
        setBadgesLocalStorage(_badgesLocalStorage);
      } else {
        setBadgesLocalStorage({ ..._badgesLocalStorage, [title]: title });
      }
    },
    [badgesLocalStorage, setBadgesLocalStorage, hasBadge]
  );

  const displayBadgesCount = useCallback((): string => {
    let badgeCount = 0;
    for (const prop in badgesLocalStorage) {
      if (badgesLocalStorage.hasOwnProperty(prop)) {
        badgeCount++;
      }
    }
    return `${
      filterIncompleteBadges ? badges.length - badgeCount : badgeCount
    }/${badges.length}`;
  }, [badgesLocalStorage, filterIncompleteBadges, badges]);

  const handleFilterCompleteBadges = useCallback((): void => {
    if (!filterCompleteBadges) {
      setFilterIncompleteBadges(false);
    }

    setFilterCompleteBadges(!filterCompleteBadges);
  }, [
    filterCompleteBadges,
    setFilterIncompleteBadges,
    setFilterCompleteBadges,
  ]);

  const handleFilterIncompleteBadges = useCallback((): void => {
    if (!filterIncompleteBadges) {
      setFilterCompleteBadges(false);
    }

    setFilterIncompleteBadges(!filterIncompleteBadges);
  }, [
    filterIncompleteBadges,
    setFilterCompleteBadges,
    setFilterIncompleteBadges,
  ]);

  const onOpenModalClick = useCallback((): void => {
    setIsModalOpen(true);
    setImportBadgesJSON("");
  }, [setIsModalOpen, setImportBadgesJSON]);

  const onCloseModalClick = useCallback((): void => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const onImportBadgesClick = useCallback((): void => {
    fakeAPICall(`/badges/import/${playerName}`);
    try {
      const parsedBadges = JSON.parse(importBadgesJSON);

      setBadgesLocalStorage(parsedBadges);

      onCloseModalClick();
    } catch (err) {
      alert("Invalid JSON. Verify what you typed and try again.");
    }
  }, [
    playerName,
    fakeAPICall,
    importBadgesJSON,
    setBadgesLocalStorage,
    onCloseModalClick,
  ]);

  const onExportBadgesClick = useCallback((): void => {
    fakeAPICall(`/badges/export/${playerName}`);
    const badges = badgesLocalStorage || {};
    const blob = new Blob([JSON.stringify(badges)], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, `badges_${playerName}.txt`);
  }, [fakeAPICall, badgesLocalStorage, playerName]);

  // used to display the solution for riddles
  const onDescriptionClick = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>): void => {
      if (e.detail === 3) {
        setIsDisplayRiddleSolution(
          (isDisplayRiddleSolution) => !isDisplayRiddleSolution
        );
      }
    },
    [setIsDisplayRiddleSolution]
  );

  return {
    isLoading,
    onSortBadgesByTitleClick,
    onSortBadgesByStarsClick,
    onDescriptionClick,
    onExportBadgesClick,
    onImportBadgesClick,
    onOpenModalClick,
    onCloseModalClick,
    handleFilterIncompleteBadges,
    handleFilterCompleteBadges,
    displayBadgesCount,
    onBadgeRowClick,
    hasBadge,
    filterCompleteBadges,
    filterIncompleteBadges,
    fakeAPICall,
    tableRef,
    sortByStars,
    sortByTitles,
    badges,
    isModalOpen,
    isDisplayRiddleSolution,
    importBadgesJSON,
    setImportBadgesJSON,
  };
};
