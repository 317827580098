import styled from "styled-components";
import { SizeProps } from "./types";

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Text = styled.div<SizeProps>`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${(props) => (props.$size === "large" ? 0.7 : 0.5)}em;
  color: white; /* Adjust color as needed */
  font-weight: bold;
  text-shadow: 0px 0px 5px black;
  pointer-events: none;
`;

export const Image = styled.img<SizeProps>`
  width: ${(props) => (props.$size === "large" ? 150 : 65)}px;
  height: auto;
`;
