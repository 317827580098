import { useMemo } from "react";
import { UseGameCardHookProps, UseGameCardHookResponse } from "./types";

export const useGameCardHook = ({
  selectedGame,
  title,
}: UseGameCardHookProps): UseGameCardHookResponse => {
  const isSelected = useMemo(
    (): boolean => selectedGame === title,
    [selectedGame, title]
  );

  return { isSelected };
};
