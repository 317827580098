import React from "react";
import { DownArrowProps } from "./types";
import { DownArrowIcon } from "./styles";

export const DownArrow = React.memo(
  ({ positionDifference }: DownArrowProps): JSX.Element => {
    return (
      <>
        {positionDifference}
        <DownArrowIcon />
      </>
    );
  }
);
