import { useMemo } from "react";
import { UseLivesHookProps, UseLivesHookResponse } from "./types";
import { EmptyHeartIcon, FilledHeartIcon } from "./styles";

export const useLivesHook = ({
  maxLives,
  lives,
}: UseLivesHookProps): UseLivesHookResponse => {
  const currentLifeLosses = maxLives - lives;

  const renderFilledHearts = useMemo(
    (): JSX.Element[] =>
      Array.from({ length: maxLives - currentLifeLosses }, (_, i) => (
        <FilledHeartIcon key={i} />
      )),
    [maxLives, currentLifeLosses]
  );

  const renderEmptyHearts = useMemo(
    (): JSX.Element[] =>
      Array.from({ length: currentLifeLosses }, (_, i) => (
        <EmptyHeartIcon key={i} />
      )),
    [currentLifeLosses]
  );

  return { renderEmptyHearts, renderFilledHearts };
};
