import { useCallback } from "react";
import { UseCheckboxHookProps, UseCheckboxHookResponse } from "./types";
import { useAnalyticsEventTracker } from "../../hooks";

export const useCheckboxHook = ({
  onValueChange,
  value,
  dataTrackId,
}: UseCheckboxHookProps): UseCheckboxHookResponse => {
  const { eventTracker } = useAnalyticsEventTracker("checkbox");

  const handleOnValueChange = useCallback(() => {
    // only tracks values that are about to become true
    if (!value && dataTrackId) {
      eventTracker(dataTrackId);
    }
    onValueChange();
  }, [value, dataTrackId, onValueChange, eventTracker]);

  return { handleOnValueChange };
};
