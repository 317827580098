import { useAppDispatch } from "@/hooks/useAppDispatch";
import { Badge } from "@/redux/models/BadgesDB";
import { deleteBadge } from "@/redux/reducers/badges/thunk";
import { useState } from "react";

export const useBadgesHook = () => {
  const dispatch = useAppDispatch();
  const [selectedBadge, setSelectedBadge] = useState<Badge | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const onDeleteClick = (id: string) => () => {
    dispatch(deleteBadge(id));
  };

  const onUpdateClick = (badge: Badge) => () => {
    setSelectedBadge(badge);
    setIsEditing(true);
  };

  const onDismissClick = () => {
    setSelectedBadge(null);
    setIsEditing(false);
  };

  return {
    onDeleteClick,
    onUpdateClick,
    onDismissClick,
    isEditing,
    selectedBadge,
  };
};
