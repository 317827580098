import React from "react";
import { usePlayerLinksTableHook } from "./hook";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Title,
  ToggleButton,
} from "./styles";
import { PlayerLinksTableProps } from "./types";

export const PlayerLinksTable = React.memo(
  ({ playerLinks, title, actions }: PlayerLinksTableProps): JSX.Element => {
    const { isEmailVisible, toggleEmailVisibility } = usePlayerLinksTableHook();

    return (
      <>
        <Title>{title}</Title>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>
                <ToggleButton onClick={toggleEmailVisibility}>
                  {isEmailVisible ? "🙈 Hide Email" : "👁 View Email"}
                </ToggleButton>
              </TableHeader>
              <TableHeader>Player Name</TableHeader>
              {actions && <TableHeader>Actions</TableHeader>}
            </TableRow>
          </thead>
          <tbody>
            {playerLinks.map((playerLink) => (
              <TableRow key={playerLink.email}>
                <TableCell>
                  {isEmailVisible ? playerLink.email : "*********"}
                </TableCell>
                <TableCell>{playerLink.playerName}</TableCell>
                {actions && <TableCell>{actions(playerLink._id)}</TableCell>}
              </TableRow>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
);
