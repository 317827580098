import ReactModal from "react-modal";
import styled from "styled-components";

export const LabelWrapper = styled.p`
  margin: 0;
  cursor: pointer;
  margin-left: 16px;
  font-size: 24px;
  display: flex;
  align-items: baseline;
`;

export const Label = styled.span`
  margin-left: 16px;
`;

export const Modal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  background-color: #1e1e1e;
  color: white;
  font-size: 20px;
  outline: none;
`;
