import React, { useEffect, useState, useCallback } from "react";
import {
  OptionsWrapper,
  TableWrapper,
  Table,
  Row,
  LeftAlign,
  CenterAlign,
  DifferenceWrapper,
  Loading,
  Error,
} from "./styles";

import { Checkbox } from "../../../../components";
import { useActions, useTypedSelector } from "../../../../hooks";
import { LeaderboardScoreDifference, PositionDifference } from "./components";
import { NewRank } from "../Summary/components";

export const Leaderboard = React.memo((): JSX.Element => {
  const { fetchLeaderboard } = useActions();

  const [showScoreDifferenceToTopPlayer, setShowScoreDifferenceToTopPlayer] =
    useState(false);
  const {
    player: { player_name },
    leaderboard,
    isLoadingLeaderboard,
    errorMessageLeaderboard,
    location: { name: city },
  } = useTypedSelector(({ playerInfo }) => playerInfo);

  useEffect(() => {
    fetchLeaderboard(city);
  }, [fetchLeaderboard, city]);

  const onShowScoreDifferenceToTopPlayerClick = useCallback(() => {
    setShowScoreDifferenceToTopPlayer((prev) => !prev);
  }, []);

  return (
    <div>
      <OptionsWrapper>
        <Checkbox
          value={showScoreDifferenceToTopPlayer}
          onValueChange={onShowScoreDifferenceToTopPlayerClick}
          label="Show score difference to Top 1 Player"
          data-trackid="show score difference on leaderboard"
        />
      </OptionsWrapper>

      <h1>{city} Leaderboard</h1>

      <TableWrapper>
        {isLoadingLeaderboard ? (
          <Loading>Calculating Ranks</Loading>
        ) : errorMessageLeaderboard ? (
          <Error>{errorMessageLeaderboard}</Error>
        ) : (
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Position</th>
                <th> Name </th>
                <th> Score </th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.map(
                (
                  {
                    players,
                    local_score,
                    positionDifference,
                    scoreDifference,
                    scoreDifferenceFromTopPlayerScore,
                  },
                  i
                ) => {
                  const position = i + 1;
                  const rank = Math.floor(local_score / 25000);
                  const isCurrentUser = (players as string) === player_name;

                  return (
                    <Row key={i} $isCurrentUser={isCurrentUser}>
                      <CenterAlign>
                        <NewRank rank={rank} size="small" />
                      </CenterAlign>
                      <CenterAlign>{position}</CenterAlign>
                      <DifferenceWrapper>
                        {players as string}
                        <PositionDifference
                          positionDifference={positionDifference}
                        />
                      </DifferenceWrapper>
                      <LeftAlign>
                        {local_score}
                        <LeaderboardScoreDifference
                          scoreDifference={
                            showScoreDifferenceToTopPlayer
                              ? scoreDifferenceFromTopPlayerScore
                              : scoreDifference
                          }
                        />
                      </LeftAlign>
                    </Row>
                  );
                }
              )}
            </tbody>
          </Table>
        )}
      </TableWrapper>
    </div>
  );
});
