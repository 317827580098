import { Link } from "react-router-dom";
import styled from "styled-components";

export const OuterWrapper = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0 40px;
  box-sizing: border-box;
`;

export const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const AdminLink = styled(Link)`
  color: #a970ff; /* Light purple */
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  border: 2px solid #a970ff;
  padding: 6px 12px;
  border-radius: 5px;
  transition: all 0.3s ease;

  &:hover {
    background: #a970ff;
    color: white;
  }
`;

export const Button = styled.button`
  background-color: #4285f4; /* Google blue */
  color: white;
  border: none;
  padding: 8px 16px;
  margin-left: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #357ae8;
  }
`;
