import { useActions, useTypedSelector } from "../../../../../../hooks";
import { UseListPlayersToRemoveHookResponse } from "./types";

export const useListPlayersToRemoveHook =
  (): UseListPlayersToRemoveHookResponse => {
    const { comparePlayers } = useTypedSelector(({ playerInfo }) => playerInfo);
    const { removePlayerListPlayers } = useActions();

    return { comparePlayers, removePlayerListPlayers };
  };
