import { useMemo } from "react";
import { usePlayerInfo } from "../../../../../../hooks";
import { getTopScores } from "../../../../utils";
import { UseCompareToYourselfTableResponse } from "./types";

export const useCompareToYourselfTableProps =
  (): UseCompareToYourselfTableResponse => {
    const { playerProfiles } = usePlayerInfo();
    const topScores = useMemo(
      () => getTopScores(playerProfiles),
      [playerProfiles]
    );

    return { playerProfiles, topScores };
  };
