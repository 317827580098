import styled from "styled-components";
import { WrapperProps } from "./types";

export const Wrapper = styled.div<WrapperProps>`
  padding: 12px 0;
  text-align: center;
  font-size: 24px;

  a {
    color: black;
  }

  background-color: ${(props) =>
    props.$bannerType === "success" ? "greenyellow" : "red"};
`;

export const Bold = styled.strong``;
