import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTable = styled.table`
  text-align: right;
  border-collapse: collapse;
  margin-bottom: 24px;

  th,
  td {
    padding: 8px;
    border: 1px solid white;
  }

  th {
    text-align: center;
  }
`;
