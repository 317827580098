import styled from "styled-components";

export const Wrapper = styled.div``;

export const Dropdown = styled.select`
  padding: 0 16px;
  border-radius: 26px;
  font-size: 18px;
  width: 240px;
  height: 30px;
`;
