import styled from "styled-components";
import { TapButtonProps } from "./types";

export const Container = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 20px;
`;

export const Tabs = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

export const TabButton = styled.button<TapButtonProps>`
  padding: 10px 20px;
  background: ${({ $active }) => ($active ? "#007bff" : "#f4f4f4")};
  color: ${({ $active }) => ($active ? "white" : "#333")};
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;

  &:hover {
    background: ${({ $active }) => ($active ? "#0056b3" : "#ddd")};
  }
`;
