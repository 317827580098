import styled from "styled-components";

import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  padding: 8px;
  border-radius: 4px;
  background-color: #008060;
  color: white;
  text-decoration: none;

  &:hover {
    background-color: #00664d;
  }
`;
