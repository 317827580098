import { useAppDispatch } from "@/hooks/useAppDispatch";
import { updateBadge } from "@/redux/reducers/badges/thunk";
import { useState } from "react";
import { UseEditBadgeHookProps, UseEditBadgeHookResponse } from "./types";

export const useEditBadgeHook = ({
  badge,
  onClose,
}: UseEditBadgeHookProps): UseEditBadgeHookResponse => {
  const dispatch = useAppDispatch();
  const [badgeTitle, setBadgeTitle] = useState(badge.title);
  const [badgeDescription, setBadgeDescription] = useState(badge.description);

  const onBadgeTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBadgeTitle(e.target.value);
  };

  const onBadgeDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBadgeDescription(e.target.value);
  };

  const onSubmit = (): void => {
    if (!badgeTitle.trim() || !badgeDescription.trim()) return;
    dispatch(
      updateBadge({
        _id: badge._id,
        title: badgeTitle,
        description: badgeDescription,
      })
    );
    onClose();
  };

  return {
    onSubmit,
    badgeTitle,
    badgeDescription,
    onBadgeTitleChange,
    onBadgeDescriptionChange,
  };
};
