import { BREAKPOINT } from "@/styles/constants";
import styled from "styled-components";
import { TextInput } from "../TextInput/component";
import { ButtonProps, InputProps } from "./types";

export const SearchInputWithErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const Input = styled(TextInput)<InputProps>`
  margin-right: 16px;
  padding: 0 16px;
  border-radius: 26px;
  font-size: 18px;
  height: ${(props) => (props.$size === "large" ? 44 : 30)}px;
  width: ${(props) => (props.$size === "large" ? 550 : 250)}px;

  @media (max-width: ${BREAKPOINT.mobile}) {
    width: 100%;
    margin-right: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: ${BREAKPOINT.mobile}) {
    flex-direction: column;
  }
`;

export const Button = styled.button<ButtonProps>`
  height: 44px;
  text-transform: uppercase;
  border-radius: 28px;
  padding: 0 20px;
  font-size: 18px;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  height: ${(props) => (props.$size === "large" ? 44 : 30)}px;
`;

export const Error = styled.div`
  color: red;
  text-align: center;
  margin-top: 16px;
`;
