import React from "react";
import { StarsProps } from "./types";
import {
  StarIcon,
  StarsCount,
  StarsCountWrapper,
  Text,
  Wrapper,
} from "./styles";

export const Stars = React.memo(({ stars }: StarsProps): JSX.Element => {
  return (
    <Wrapper>
      <StarIcon />
      <StarsCountWrapper>
        <StarsCount>{stars}</StarsCount>
        <Text>Stars</Text>
      </StarsCountWrapper>
    </Wrapper>
  );
});
