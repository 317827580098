import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  min-width: 300px;
`;

export const LoginOption = styled.a`
  display: block;
  background-color: #4285f4;
  color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #357ae8;
  }

  &:nth-child(2) {
    background-color: #5865f2; /* Discord Blue */
  }

  &:nth-child(2):hover {
    background-color: #4752c4;
  }
`;

export const CloseButton = styled.button`
  background: red;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;

  &:hover {
    background: darkred;
  }
`;
