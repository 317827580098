import React, { useCallback, useEffect, useState } from "react";
import {
  OptionsWrapper,
  TableWrapper,
  Table,
  Row,
  LeftAlign,
  CenterAlign,
  DifferenceWrapper,
  Loading,
  Error,
} from "./styles";

import { useActions, useTypedSelector } from "../../../../hooks/";
import { LeaderboardScoreDifference, PositionDifference } from "./components";
import { Checkbox } from "../../../../components";
import { NewRank } from "../Summary/components";

export const WorldLeaderboard = React.memo((): JSX.Element => {
  const { fetchLeaderboard } = useActions();

  const [showHighlightLocationPlayers, setShowHighlightLocationPlayers] =
    useState(false);

  const {
    player: { player_name },
    location: { name: locationName },
    leaderboard,
    isLoadingLeaderboard,
    errorMessageLeaderboard,
  } = useTypedSelector(({ playerInfo }) => playerInfo);

  useEffect(() => {
    fetchLeaderboard("world");
  }, [fetchLeaderboard]);

  const onShowHighlightLocationPlayersClick = useCallback(() => {
    setShowHighlightLocationPlayers((prev) => !prev);
  }, []);

  return (
    <div>
      <OptionsWrapper>
        <Checkbox
          value={showHighlightLocationPlayers}
          onValueChange={onShowHighlightLocationPlayersClick}
          label="Highlight players from your location"
          data-trackid="show highlighted players from your location"
        />
      </OptionsWrapper>

      <h1>World Leaderboard</h1>

      <TableWrapper>
        {isLoadingLeaderboard ? (
          <Loading>Calculating Ranks</Loading>
        ) : errorMessageLeaderboard ? (
          <Error>{errorMessageLeaderboard}</Error>
        ) : (
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Position</th>
                <th>Name</th>
                <th>Score</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.map(
                (
                  {
                    players,
                    local_score,
                    city,
                    positionDifference,
                    scoreDifference,
                  },
                  i
                ) => {
                  const isSameLocation = locationName === city;
                  const isCurrentUser = (players as string) === player_name;
                  const position = i + 1;
                  const rank = Math.floor(local_score / 25000);

                  return (
                    <Row
                      key={i}
                      $isCurrentUser={isCurrentUser}
                      $isHighlighted={
                        showHighlightLocationPlayers &&
                        isSameLocation &&
                        !isCurrentUser
                      }
                    >
                      <CenterAlign>
                        <NewRank rank={rank} size="small" />
                      </CenterAlign>
                      <CenterAlign>{position}</CenterAlign>
                      <DifferenceWrapper>
                        {players as string}
                        <PositionDifference
                          positionDifference={positionDifference}
                        />
                      </DifferenceWrapper>
                      <LeftAlign>
                        {local_score}
                        <LeaderboardScoreDifference
                          scoreDifference={scoreDifference}
                        />
                      </LeftAlign>
                      <LeftAlign>{city}</LeftAlign>
                    </Row>
                  );
                }
              )}
            </tbody>
          </Table>
        )}
      </TableWrapper>
    </div>
  );
});
