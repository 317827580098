import { useMemo } from "react";
import { UseGameCardListHookResponse, UseGameCardListHookProps } from "./types";

export const useGameCardListHook = ({
  games,
}: UseGameCardListHookProps): UseGameCardListHookResponse => {
  const gameNames = useMemo(() => games.map(({ name }) => name), [games]);
  const firstBatch = useMemo(() => gameNames.slice(0, 4), [gameNames]);
  const remainingGames = useMemo(() => gameNames.slice(4), [gameNames]);

  return { firstBatch, remainingGames };
};
