import { useStopPropagation } from "@/hooks";
import React from "react";
import { useEditLocationHook } from "./hook";
import { Button, Input, ModalContainer, ModalOverlay } from "./styles";
import { EditLocationProps } from "./types";

export const EditLocation = React.memo(
  ({ location, onClose }: EditLocationProps): JSX.Element => {
    const { onSubmit, locationName, onLocationNameChange } =
      useEditLocationHook({
        location,
        onClose,
      });
    const { stopPropagation } = useStopPropagation();

    return (
      <ModalOverlay onClick={onClose}>
        <ModalContainer onClick={stopPropagation}>
          <Input
            type="text"
            value={locationName}
            onChange={onLocationNameChange}
            placeholder="Edit location name"
          />
          <Button type="submit" onClick={onSubmit}>
            Update
          </Button>
          <Button onClick={onClose} $cancel>
            Cancel
          </Button>
        </ModalContainer>
      </ModalOverlay>
    );
  }
);
