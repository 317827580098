import styled, { css } from "styled-components";
import { PersonalRecordProps } from "./types";

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Table = styled.table`
  text-align: right;
  border-collapse: collapse;
  margin-bottom: 24px;

  tr,
  th,
  td {
    border: 1px solid white;
  }

  th,
  td {
    padding: 8px;
  }

  th {
    text-align: center;
  }
`;

export const PersonalRecord = styled.td<PersonalRecordProps>`
  ${({ $highlight }) =>
    $highlight &&
    css`
      color: lightgreen;
      font-weight: 600;
    `}
`;
