import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { StrategyGame } from "./components/StrategyGame";
import { useActions, useTypedSelector } from "../../hooks";
import { Wrapper } from "./styles";

export const Strategy = (): JSX.Element => {
  const { level } = useParams<{ level: string }>();
  const { fetchStrategyGame } = useActions();

  const { loading, ...strategyGame } = useTypedSelector(
    ({ strategy }) => strategy
  );

  useEffect(() => {
    fetchStrategyGame(level || "");
  }, [fetchStrategyGame, level]);

  return (
    <Wrapper>
      <StrategyGame loading={loading} strategyGame={strategyGame} />
    </Wrapper>
  );
};
