import { Banner } from "@/components";
import { useActions, useTypedSelector } from "@/hooks";
import { useAppDispatch } from "@/hooks/useAppDispatch";
import { resetPlayerLinksState } from "@/redux/reducers/playerLinks/reducer";
import { useCallback, useEffect, useState } from "react";
import { Leaderboard, Room, Summary, WorldLeaderboard } from "./components";
import { PlayerBadges } from "./components/NewBadges/component";
import { MenuActive, RoomNameAndId } from "./components/SideMenu/types";
import { UseActivateHookResponse } from "./types";

export const useActivateHook = (): UseActivateHookResponse => {
  const dispatch = useAppDispatch();
  const {
    playerInfo: { player, location, locations },
    lastUpdated: { isApiDown },
  } = useTypedSelector(({ playerInfo, lastUpdated }) => ({
    playerInfo,
    lastUpdated,
  }));
  const { clearPreviousSearch, clearPlayerYesterday, clearPlayerAllLocations } =
    useActions();

  const [menuActive, setMenuActive] = useState<MenuActive>("Account Summary");
  const [room, setRoom] = useState<RoomNameAndId>({ roomName: "Hoops" });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onRoomClick = useCallback((room: RoomNameAndId) => {
    setRoom(room);
  }, []);

  useEffect(() => {
    if (locations.length && !player.player_name) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [locations, player]);

  const handleBackButtonClick = useCallback((): void => {
    if (menuActive !== "Account Summary") {
      setMenuActive("Account Summary");
    } else {
      clearPlayerAllLocations();
      clearPlayerYesterday();
      clearPreviousSearch();
      dispatch(resetPlayerLinksState());
    }
  }, [
    menuActive,
    clearPreviousSearch,
    clearPlayerAllLocations,
    clearPlayerYesterday,
    dispatch,
  ]);

  const renderContent = useCallback((): JSX.Element => {
    switch (menuActive) {
      case "Account Summary":
        return <Summary player={player} location={location} />;
      case "Games":
        const _room = location.rooms.find(
          (r) =>
            r.id === room.roomId || (!room.roomId && r.name === room.roomName)
        );
        return <Room room={_room} location={location.name} />;
      case "Leaderboards":
        return <Leaderboard />;
      case "World Leaderboard":
        return <WorldLeaderboard />;
      case "Badges":
        return <PlayerBadges playerName={player.player_name} />;
      // isFeatureEnabled("login") ? (
      // ) : (
      //   <Badges playerName={player.player_name} />
      // );
      default:
        return <></>;
    }
  }, [menuActive, player, location, room]);

  const renderHeader = useCallback((): JSX.Element => {
    if (isApiDown) {
      return (
        <Banner
          bannerType="alert"
          message="API is down - ping Gondim on Discord"
        />
      );
    }

    return <></>;
    // else {
    //   return (
    //     <LastUpdated>
    //       <p>Players last updated: {lastUpdatedPlayers}</p>
    //       <p>Leaderboards last updated: {lastUpdatedLeaderboards}</p>
    //     </LastUpdated>
    //   );
    // }
  }, [isApiDown]);

  return {
    menuActive,
    onRoomClick,
    room,
    handleBackButtonClick,
    isModalOpen,
    setIsModalOpen,
    setMenuActive,
    renderContent,
    renderHeader,
  };
};
