import { activateAPI } from "@/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsLoading, setUser } from "./reducer";

export const fetchCurrentUser = createAsyncThunk(
  "auth/fetchCurrentUser",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      const res = await activateAPI.get("/auth/current_user");

      dispatch(setUser(res.data)); // ✅ Update Redux with user data
    } catch (error) {
      console.error("Error fetching current user:", error);
      dispatch(setUser(null)); // ✅ Ensure user is logged out if request fails
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
