import styled from "styled-components";

export const PersonalRecordCell = styled.td`
  color: lightgreen;
  font-weight: 600;
`;

export const MonthlyScoreRow = styled.tr`
  background-color: magenta;
  cursor: pointer;
`;
