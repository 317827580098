import { FaFilter } from "react-icons/fa";

import { Checkbox, RadioGroup } from "../../../../components";
import { IMPROVE_LEVELS_BY_DEFAULT_ITEMS } from "./constants";
import { Label, LabelWrapper, Modal } from "./styles";
import { useFiltersHook } from "./hook";
import { FiltersProps } from "./types";

export const Filters = ({
  handleImproveScoreBy,
  improveScoreBy,
}: FiltersProps): JSX.Element | null => {
  const {
    closeModal,
    openModal,
    isModalOpen,
    isComparingOldScores,
    isComparingWithOtherLocations,
    isMultiGameView,
    toggleMultiGameView,
    showScoreDifference,
    showOnlyDifferentLevels,
    showOnlyLevelsToMatchScore,
    showOnlyLevelsToImprove,
    showOnlyMissingLevels,
    showOnlyNotTopScores,
    showOnlyTopScores,
    showTotalScorePerGame,
    comparePlayers,
    toggleShowOnlyDifferentLevels,
    toggleShowOnlyLevelsToImprove,
    toggleShowOnlyLevelsToMatchScore,
    toggleShowOnlyMissingLevels,
    toggleShowOnlyNotTopScores,
    toggleShowOnlyTopScores,
    toggleShowScoreDifferenceGameLevel,
    toggleShowTotalScorePerGame,
  } = useFiltersHook();

  Modal.setAppElement("#root");

  return (
    <>
      <LabelWrapper onClick={openModal}>
        <FaFilter /> <Label>Filters</Label>
      </LabelWrapper>
      <Modal
        contentLabel="Filters"
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick
        onRequestClose={closeModal}
      >
        <div>
          {isComparingWithOtherLocations || isComparingOldScores ? (
            <>
              <Checkbox
                value={isMultiGameView}
                onValueChange={toggleMultiGameView}
                label="Multi-Game View"
                data-trackid="multi-game view"
              />
            </>
          ) : (
            <>
              <Checkbox
                value={showScoreDifference}
                onValueChange={toggleShowScoreDifferenceGameLevel}
                label="Show difference from top score"
                data-trackid="show score difference"
              />
              <Checkbox
                value={showTotalScorePerGame}
                onValueChange={toggleShowTotalScorePerGame}
                label="Show total score per game"
                data-trackid="show total score per game"
              />
              {Boolean(comparePlayers.length) ? (
                <>
                  <Checkbox
                    value={showOnlyDifferentLevels}
                    onValueChange={toggleShowOnlyDifferentLevels}
                    label="Show only different levels"
                    data-trackid="filter by different levels"
                  />
                  <Checkbox
                    value={showOnlyLevelsToMatchScore}
                    label="Show only levels to match score"
                    onValueChange={toggleShowOnlyLevelsToMatchScore}
                    data-trackid="filter by levels to match score"
                  />
                </>
              ) : (
                <div>
                  <Checkbox
                    value={showOnlyTopScores}
                    onValueChange={toggleShowOnlyTopScores}
                    label="Show only top scores"
                    data-trackid="filter by top scores"
                  />
                  <Checkbox
                    value={showOnlyNotTopScores}
                    onValueChange={toggleShowOnlyNotTopScores}
                    label="Show only NOT top scores"
                    data-trackid="filter by not top scores"
                  />
                  <Checkbox
                    value={showOnlyMissingLevels}
                    onValueChange={toggleShowOnlyMissingLevels}
                    label="Show only missing levels"
                    data-trackid="filter by missing levels"
                  />
                  <div>
                    <Checkbox
                      value={showOnlyLevelsToImprove}
                      onValueChange={toggleShowOnlyLevelsToImprove}
                      label="Show only levels to improve score"
                      data-trackid="filter by levels to improve score"
                    />
                    {showOnlyLevelsToImprove && (
                      <RadioGroup
                        name="improveScore"
                        onChange={handleImproveScoreBy}
                        value={improveScoreBy}
                        radioItems={IMPROVE_LEVELS_BY_DEFAULT_ITEMS}
                      />
                    )}
                  </div>
                </div>
              )}
              <Checkbox
                value={isMultiGameView}
                onValueChange={toggleMultiGameView}
                label="Multi-Game View"
                data-trackid="multi-game view"
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
