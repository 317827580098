import { useAppSelector } from "@/hooks/useAppSelector";
import { selectLocationsState } from "@/redux/reducers/locations/reducer";
import { AddLocation } from "./components/AddLocation/component";
import { LocationsTable } from "./components/LocationsTable/component";
import {
  AdminContainer,
  HeaderSection,
  PageTitle,
  ScrollableContent,
} from "./styles";

export const Locations = (): JSX.Element => {
  const { error, isLoading, locations } = useAppSelector(selectLocationsState);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <AdminContainer>
      <HeaderSection>
        <PageTitle>Activate Locations</PageTitle>
        <AddLocation />
      </HeaderSection>
      <ScrollableContent>
        <LocationsTable locations={locations} />
      </ScrollableContent>
    </AdminContainer>
  );
};
