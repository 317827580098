import { Checkbox } from "../../../../components";
import { AVAILABLE_STRATEGY_LEVELS } from "../../constants";
import { Lives } from "../Lives";
import { Tile } from "../Tile";
import { useStrategyGameHook } from "./hook";
import {
  Button,
  CalibratingSpinner,
  CalibratingText,
  Cell,
  CheckboxWrapper,
  Column,
  Congratulations,
  Grid,
  LevelLink,
  LevelTitle,
  LevelsWrapper,
  LoadingSpinner,
  SecondsLeft,
  TargetsLeft,
  Wrapper,
} from "./styles";
import { StrategyGameProps } from "./types";

export const StrategyGame = ({
  strategyGame,
  loading,
}: StrategyGameProps): JSX.Element => {
  const {
    countdownTimer,
    gridCount,
    isTargetLoaded,
    lives,
    pauseTimer,
    playWithReds,
    resetGame,
    resetWave,
    setPauseTimer,
    setPlayWithReds,
    targets,
    wave,
    findTargetPosition,
    findGunPosition,
    findRedPosition,
  } = useStrategyGameHook({ strategyGame });

  if (loading || !strategyGame) {
    return (
      <LoadingSpinner>
        <CalibratingText>Calibrating</CalibratingText>
        <CalibratingSpinner />
      </LoadingSpinner>
    );
  }

  if (strategyGame.error) {
    return <div>{strategyGame.error}</div>;
  }

  return (
    <>
      <LevelsWrapper>
        <LevelTitle>Levels</LevelTitle>
        {AVAILABLE_STRATEGY_LEVELS.map((level) => (
          <LevelLink
            $isSelected={strategyGame.level === level.toString()}
            key={level}
            to={`/strategy/${level}`}
          >
            {level}
          </LevelLink>
        ))}
      </LevelsWrapper>
      <LevelTitle>Strategy {strategyGame.level}</LevelTitle>
      <SecondsLeft>{countdownTimer} seconds left</SecondsLeft>
      <Lives lives={lives} />
      {targets.length && wave <= strategyGame.targets.length - 1 ? (
        <TargetsLeft>{targets.length} targets left</TargetsLeft>
      ) : (
        isTargetLoaded && <Congratulations>Congratulations!</Congratulations>
      )}
      <Wrapper>
        <Button onClick={resetWave}>Reset Wave</Button>
        <Grid>
          {gridCount.map((_, x) => {
            return (
              <Column key={x}>
                {gridCount.map((_, y) => (
                  <Cell key={y}>
                    {isTargetLoaded && targets.length === 0 ? (
                      <Tile color="green" />
                    ) : !lives || !countdownTimer ? (
                      <Tile color="red" />
                    ) : (
                      <>
                        {findRedPosition({ x, y }) ||
                          findTargetPosition({ x, y })}
                        {findGunPosition({ x, y })}
                      </>
                    )}
                  </Cell>
                ))}
              </Column>
            );
          })}
        </Grid>
        <Button onClick={resetGame}>Reset Game</Button>
      </Wrapper>
      <CheckboxWrapper>
        <Checkbox
          label="Play with reds"
          onValueChange={() => setPlayWithReds(!playWithReds)}
          value={playWithReds}
          data-trackid="strategy - play with reds"
        />
        <Checkbox
          label="Pause timer"
          onValueChange={() => setPauseTimer(!pauseTimer)}
          value={pauseTimer}
          data-trackid="strategy - pause timer"
        />
      </CheckboxWrapper>
    </>
  );
};
