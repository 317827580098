import { PlayerLinksTable } from "../PlayerLinksTable";
import { useApprovedPlayerLinksTable } from "./hook";

export const ApprovedPlayerLinksTable = (): JSX.Element | null => {
  const { approvedPlayers, actions } = useApprovedPlayerLinksTable();

  if (approvedPlayers.length === 0) {
    return null;
  }

  return (
    <PlayerLinksTable
      title="Approved"
      playerLinks={approvedPlayers}
      actions={actions}
    />
  );
};
