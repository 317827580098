import { FEATURE_FLAGS_KEY } from "./constants";
import { FeatureFlag } from "./types";

export const isFeatureEnabled = (feature: FeatureFlag): boolean => {
  const flags = getFeatureFlags();

  return Boolean(flags[feature]);
};

/**
 * Retrieves all feature flags from localStorage
 */
const getFeatureFlags = (): Record<string, boolean> => {
  const flags = localStorage.getItem(FEATURE_FLAGS_KEY);
  return flags ? JSON.parse(flags) : {};
};

/**
 * Saves the feature flags back to localStorage
 */
const setFeatureFlags = (flags: Record<string, boolean>) => {
  localStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify(flags));
};

/**
 * Adds a new feature flag (enabled by default)
 */
const addFeatureFlag = (feature: string): void => {
  const flags = getFeatureFlags();
  if (!(feature in flags)) {
    flags[feature] = true; // Enable by default
    setFeatureFlags(flags);
    console.log(`✅ Feature flag '${feature}' added and enabled.`);
  } else {
    console.log(`⚠️ Feature flag '${feature}' already exists.`);
  }
};

/**
 * Removes a feature flag
 */
const removeFeatureFlag = (feature: string): void => {
  const flags = getFeatureFlags();
  if (feature in flags) {
    delete flags[feature];
    setFeatureFlags(flags);
    console.log(`❌ Feature flag '${feature}' removed.`);
  } else {
    console.log(`⚠️ Feature flag '${feature}' does not exist.`);
  }
};

/**
 * Toggles a feature flag between true and false
 */
const toggleFeatureFlag = (feature: string): void => {
  const flags = getFeatureFlags();
  if (feature in flags) {
    flags[feature] = !flags[feature];
    setFeatureFlags(flags);
    console.log(`🔄 Feature flag '${feature}' toggled to ${flags[feature]}.`);
  } else {
    console.log(
      `⚠️ Feature flag '${feature}' does not exist. Use addFeatureFlag('${feature}') first.`
    );
  }
};

/**
 * Displays all feature flags in a readable format
 */
const viewFeatureFlags = (): void => {
  console.table(getFeatureFlags());
};

// Attach to window for global access in browser console
if (typeof window !== "undefined") {
  (window as any).addFeatureFlag = addFeatureFlag;
  (window as any).removeFeatureFlag = removeFeatureFlag;
  (window as any).toggleFeatureFlag = toggleFeatureFlag;
  (window as any).viewFeatureFlags = viewFeatureFlags;
}
