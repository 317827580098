import React from "react";
import { Bold, Wrapper } from "./styles";
import { BannerProps } from "./types";

export const Banner = React.memo(
  ({ message, bannerType, messageComponent }: BannerProps): JSX.Element => {
    return (
      <Wrapper $bannerType={bannerType}>
        <Bold>{message || messageComponent}</Bold>
      </Wrapper>
    );
  }
);
