import { activateAPI } from "@/api";
import { PlayerLinks } from "@/redux/models/PlayerLinksDB";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CheckPlayerLinkStatusProps,
  CheckPlayerLinkStatusResponse,
  PlayerLinkResponse,
  RequestPlayerLinkProps,
} from "./types";

export const fetchPlayerLinks = createAsyncThunk(
  "playerLinks/fetchPlayerLinks",
  async (_, { rejectWithValue }) => {
    try {
      const response = await activateAPI.get("/api/player-link");

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch player links");
    }
  }
);

export const checkPlayerLinkStatus = createAsyncThunk(
  "playerLinks/checkPlayerLinkStatus",
  async ({ playerName }: CheckPlayerLinkStatusProps, { rejectWithValue }) => {
    try {
      const response = await activateAPI.get<CheckPlayerLinkStatusResponse>(
        `/api/player-link/check-request/${playerName}`
      );

      return response.data;
    } catch (error) {
      console.error("Error checking player link request status:", error);
      return rejectWithValue("Failed to check player link request status");
    }
  }
);

export const requestPlayerLink = createAsyncThunk(
  "playerLinks/requestPlayerLink",
  async (
    { playerName, email }: RequestPlayerLinkProps,
    { rejectWithValue }
  ) => {
    if (!email) {
      return;
    }

    try {
      const response = await activateAPI.post<PlayerLinks>(
        "/api/player-link/request",
        {
          playerName,
          email,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error requesting player link:", error);
      return rejectWithValue("Failed to submit player link request");
    }
  }
);

export const approvePlayerLink = createAsyncThunk(
  "playerLinks/approvePlayerLink",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await activateAPI.put<PlayerLinkResponse>(
        `/api/player-link/approve/${id}`
      );

      return response.data.playerLinkId;
    } catch (error) {
      return rejectWithValue("Failed to approve player link");
    }
  }
);

export const denyPlayerLink = createAsyncThunk(
  "playerLinks/denyPlayerLink",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await activateAPI.put<PlayerLinkResponse>(
        `/api/player-link/deny/${id}`
      );

      return response.data.playerLinkId;
    } catch (error) {
      return rejectWithValue("Failed to deny player link");
    }
  }
);

export const resetPlayerLink = createAsyncThunk(
  "playerLinks/resetPlayerLink",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await activateAPI.put<PlayerLinkResponse>(
        `/api/player-link/reset/${id}`
      );

      return response.data.playerLinkId;
    } catch (error) {
      return rejectWithValue("Failed to reset player link approval");
    }
  }
);
