import { GameTitle } from "./types";

export const GAME_TITLES: GameTitle[] = [
  "Technique",
  "Bop",
  "Numbers",
  "Order Up",
  "15 Green",
  "Terminal",
  "Laser Relay",
  "Bullet Train",
];

export const PLAYERS: string[] = [
  "Gondim",
  "CrazyChris",
  "Azrael 38",
  "DrChamP",
  "JamesFromToronto",
  "Cowrider",
  "Corwyn",
  "T-bone_12",
];
