import { GameCard } from "../GameCard";
import { Wrapper, Batch } from "./styles";
import React from "react";
import { GameCardListProps } from "./types";
import { useGameCardListHook } from "./hook";

export const GameCardList = React.memo(
  ({ games, onGameClick, selectedGame }: GameCardListProps): JSX.Element => {
    const { firstBatch, remainingGames } = useGameCardListHook({ games });

    return (
      <Wrapper>
        <Batch>
          {firstBatch.map((name, i) => (
            <GameCard
              key={i}
              title={name}
              selectedGame={selectedGame}
              onGameClick={onGameClick}
            />
          ))}
        </Batch>
        {remainingGames.length > 0 && (
          <Batch>
            {remainingGames.map((name, i) => (
              <GameCard
                key={i}
                title={name}
                selectedGame={selectedGame}
                onGameClick={onGameClick}
              />
            ))}
          </Batch>
        )}
      </Wrapper>
    );
  }
);
