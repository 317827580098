import React from "react";
import { SamePositionIcon } from "./styles";

export const SamePosition = React.memo((): JSX.Element => {
  return (
    <>
      0 <SamePositionIcon />
    </>
  );
});
