import { PlayerLinksTable } from "../PlayerLinksTable";
import { usePendingPlayerLinksTableHook } from "./hook";

export const PendingPlayerLinksTable = (): JSX.Element | null => {
  const { pendingPlayers, actions } = usePendingPlayerLinksTableHook();

  if (pendingPlayers.length === 0) {
    return null;
  }

  return (
    <PlayerLinksTable
      title="Pending"
      playerLinks={pendingPlayers}
      actions={actions}
    />
  );
};
