import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #1e1e1e;
  min-height: 100vh;
`;

export const AdminWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
`;
