import React from "react";
import { RankProps } from "./types";
import { InnerPentagon, Pentagon, RankText, Wrapper } from "./styles";

export const Rank = React.memo(({ rank }: RankProps): JSX.Element => {
  return (
    <Wrapper>
      <Pentagon $rank={rank}>
        <InnerPentagon>
          <RankText>{rank}</RankText>
        </InnerPentagon>
      </Pentagon>
    </Wrapper>
  );
});
