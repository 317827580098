import React from "react";
import { UpArrowProps } from "./types";
import { UpArrowIcon } from "./styles";

export const UpArrow = React.memo(
  ({ positionDifference }: UpArrowProps): JSX.Element => {
    return (
      <>
        {positionDifference}
        <UpArrowIcon />
      </>
    );
  }
);
