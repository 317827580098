import { activateAPI } from "@/api";
import { Location } from "@/redux/models/LocationDB";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchLocations = createAsyncThunk(
  "locations/fetchLocations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await activateAPI.get("/api/locations");
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch locations");
    }
  }
);

export const addLocation = createAsyncThunk(
  "locations/addLocation",
  async (newLocation: Omit<Location, "_id">, { rejectWithValue }) => {
    try {
      const response = await activateAPI.post("/api/locations", newLocation);

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to add location");
    }
  }
);

export const updateLocation = createAsyncThunk(
  "locations/updateLocation",
  async (updatedLocation: Location, { rejectWithValue }) => {
    try {
      const response = await activateAPI.put(
        `/api/locations/${updatedLocation._id}`,
        updatedLocation
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to update location");
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "locations/deleteLocation",
  async (id: string, { rejectWithValue }) => {
    try {
      await activateAPI.delete(`/api/locations/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue("Failed to delete location");
    }
  }
);
