import { LevelRows } from "..";
import { CompareToYourselfTable } from "../CompareToYourselfTable";
import { HARDCODED_COLUMNS } from "./constants";
import { GamesListProps } from "./types";
import React from "react";
import { StyledTable, TableWrapper } from "./styles";
import { useGamesListHook } from "./hook";

export const GamesList = React.memo(
  ({ games, improveScoreBy }: GamesListProps): JSX.Element => {
    const {
      isComparingWithOtherLocations,
      filterGameLevels,
      allPlayers,
      isComparingWithOtherPlayers,
      showScoreDifference,
    } = useGamesListHook({
      improveScoreBy,
    });

    if (isComparingWithOtherLocations) {
      return <CompareToYourselfTable games={games} />;
    }

    return (
      <>
        {games.map((game, i) => {
          const filteredLevels = filterGameLevels(game);

          return (
            <TableWrapper key={`score-${i}`}>
              <StyledTable>
                <thead>
                  <tr>
                    <th colSpan={HARDCODED_COLUMNS + allPlayers.length}>
                      {game.name}
                    </th>
                  </tr>
                  <tr>
                    <th>Level</th>
                    {!isComparingWithOtherPlayers ? (
                      <>
                        <th>Times Passed</th>
                        <th>Your Score</th>
                      </>
                    ) : (
                      allPlayers.map(({ player_name }, i) => (
                        <th key={`player-${i}`}>{player_name}</th>
                      ))
                    )}
                    <th>Top Score</th>
                  </tr>
                </thead>
                <tbody>
                  <LevelRows
                    levels={filteredLevels}
                    showScoreDifference={showScoreDifference}
                  />
                </tbody>
              </StyledTable>
            </TableWrapper>
          );
        })}
      </>
    );
  }
);
