import { PlayerLinks } from "@/redux/models/PlayerLinksDB";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  approvePlayerLink,
  checkPlayerLinkStatus,
  denyPlayerLink,
  fetchPlayerLinks,
  requestPlayerLink,
  resetPlayerLink,
} from "./thunk";
import { CheckPlayerLinkStatusResponse, PlayerLinksState } from "./types";

const initialState: PlayerLinksState = {
  error: null,
  isLoading: false,
  playerLinks: [],
  requestLinkSuccess: false,
  canRequest: false,
};

const playerLinksSlice = createSlice({
  name: "playerLinks",
  initialState,
  reducers: {
    resetPlayerLinksState: (state) => {
      state.canRequest = false;
      state.error = null;
      state.isLoading = false;
      state.requestLinkSuccess = false;
      state.playerLinks = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerLinks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchPlayerLinks.fulfilled,
        (state, action: PayloadAction<PlayerLinks[]>) => {
          state.isLoading = false;
          state.playerLinks = action.payload;
        }
      )
      .addCase(fetchPlayerLinks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(
        approvePlayerLink.fulfilled,
        (state, action: PayloadAction<string>) => {
          const playerLink = state.playerLinks.find(
            ({ _id }) => _id === action.payload
          );

          if (playerLink) {
            playerLink.approved = true;
          }
        }
      )
      .addCase(approvePlayerLink.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(
        denyPlayerLink.fulfilled,
        (state, action: PayloadAction<string>) => {
          const playerLink = state.playerLinks.find(
            ({ _id }) => _id === action.payload
          );

          if (playerLink) {
            playerLink.approved = false;
          }
        }
      )
      .addCase(denyPlayerLink.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(
        resetPlayerLink.fulfilled,
        (state, action: PayloadAction<string>) => {
          const playerLink = state.playerLinks.find(
            ({ _id }) => _id === action.payload
          );

          if (playerLink) {
            playerLink.approved = undefined;
          }
        }
      )
      .addCase(resetPlayerLink.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(requestPlayerLink.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.requestLinkSuccess = false;
      })
      .addCase(requestPlayerLink.fulfilled, (state) => {
        state.isLoading = false;
        state.requestLinkSuccess = true;
      })
      .addCase(requestPlayerLink.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.requestLinkSuccess = false;
      })
      .addCase(checkPlayerLinkStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        checkPlayerLinkStatus.fulfilled,
        (state, action: PayloadAction<CheckPlayerLinkStatusResponse>) => {
          state.isLoading = false;
          state.canRequest = action.payload.canRequest;
        }
      )
      .addCase(checkPlayerLinkStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.canRequest = false;
      });
  },
});

export const {
  reducer: playerLinksReducer,
  actions: { resetPlayerLinksState },
} = playerLinksSlice;

export const selectPlayerLinksState = (state: RootState) => state.playerLinks;
