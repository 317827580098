import { useStopPropagation } from "@/hooks";
import React from "react";
import { useEditBadgeHook } from "./hook";
import { Button, Input, ModalContainer, ModalOverlay } from "./styles";
import { EditBadgeProps } from "./types";

export const EditBadge = React.memo(
  ({ badge, onClose }: EditBadgeProps): JSX.Element => {
    const {
      onSubmit,
      badgeTitle,
      badgeDescription,
      onBadgeTitleChange,
      onBadgeDescriptionChange,
    } = useEditBadgeHook({
      badge,
      onClose,
    });
    const { stopPropagation } = useStopPropagation();

    return (
      <ModalOverlay onClick={onClose}>
        <ModalContainer onClick={stopPropagation}>
          <Input
            type="text"
            value={badgeTitle}
            onChange={onBadgeTitleChange}
            placeholder="Edit badge title"
          />
          <Input
            type="text"
            value={badgeDescription}
            onChange={onBadgeDescriptionChange}
            placeholder="Edit badge description"
          />
          <Button type="submit" onClick={onSubmit}>
            Update
          </Button>
          <Button onClick={onClose} $cancel>
            Cancel
          </Button>
        </ModalContainer>
      </ModalOverlay>
    );
  }
);
