import { ChangeEvent, useCallback } from "react";
import { getOptionByValue } from "./utils";
import {
  UseDropdownSelectHookProps,
  UseDropdownSelectHookResponse,
} from "./types";

export const useDropdownSelectHook = ({
  options,
  selectedOption,
  setSelectedOption,
}: UseDropdownSelectHookProps): UseDropdownSelectHookResponse => {
  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>): void => {
      const newSelectedOption = getOptionByValue(event.target.value, options);

      setSelectedOption(newSelectedOption || selectedOption);
    },
    [options, selectedOption, setSelectedOption]
  );

  return { handleSelectChange };
};
