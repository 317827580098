import { useAppDispatch } from "@/hooks/useAppDispatch";
import { useAppSelector } from "@/hooks/useAppSelector";
import { selectPlayerLinksState } from "@/redux/reducers/playerLinks/reducer";
import {
  approvePlayerLink,
  denyPlayerLink,
} from "@/redux/reducers/playerLinks/thunk";
import { useCallback, useMemo } from "react";
import { ActionLink } from "./styles";
import { UsePendingPlayerLinksTableHookResponse } from "./types";

export const usePendingPlayerLinksTableHook =
  (): UsePendingPlayerLinksTableHookResponse => {
    const dispatch = useAppDispatch();
    const { playerLinks } = useAppSelector(selectPlayerLinksState);

    const onApproveClick = useCallback(
      (id: string) => (): void => {
        try {
          dispatch(approvePlayerLink(id));
        } catch (error) {}
      },
      [dispatch]
    );

    const onDenyClick = useCallback(
      (id: string) => (): void => {
        try {
          dispatch(denyPlayerLink(id));
        } catch (error) {}
      },
      [dispatch]
    );

    const pendingPlayers = useMemo(
      () => playerLinks.filter(({ approved }) => approved === undefined),
      [playerLinks]
    );

    const actions = (id: string): JSX.Element => (
      <>
        <ActionLink onClick={onApproveClick(id)}>Approve</ActionLink>
        <ActionLink onClick={onDenyClick(id)} $danger>
          Deny
        </ActionLink>
      </>
    );

    return { pendingPlayers, actions };
  };
