import React from "react";
import { ScoreDifferenceNegative, ScoreDifferencePositive } from "./styles";
import { ScoreDifferenceProps } from "./types";
import { useScoreDifferenceHook } from "./hook";

export const ScoreDifference = React.memo(
  ({
    score,
    topScore,
    visible = false,
    positiveSign = false,
  }: ScoreDifferenceProps): JSX.Element => {
    const { differenceToRecord } = useScoreDifferenceHook({ score, topScore });

    if (!(differenceToRecord < 0 && visible)) {
      return <></>;
    }

    return positiveSign ? (
      <ScoreDifferencePositive>
        (+{Math.abs(differenceToRecord)})
      </ScoreDifferencePositive>
    ) : (
      <ScoreDifferenceNegative>({differenceToRecord})</ScoreDifferenceNegative>
    );
  }
);
