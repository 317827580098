import { activateAPI } from "@/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FetchPlayerBadgesProps, UpdatePlayerBadgesResponse } from "./types";

// Fetch badges by playerName
export const fetchPlayerBadges = createAsyncThunk(
  "playerBadges/fetchPlayerBadges",
  async ({ playerName }: FetchPlayerBadgesProps, { rejectWithValue }) => {
    try {
      const response = await activateAPI.get(`/api/users/${playerName}/badges`);
      return response.data.badges; // Expecting an array of badge IDs
    } catch (error) {
      return rejectWithValue("Failed to fetch player badges");
    }
  }
);

// Update badges assigned to the player (batch update)
export const updatePlayerBadges = createAsyncThunk(
  "playerBadges/updatePlayerBadges",
  async (badgeIds: string[], { rejectWithValue }) => {
    try {
      const response = await activateAPI.put<UpdatePlayerBadgesResponse>(
        "/api/users/badges",
        {
          badgeIds,
        }
      );

      return response.data.badges; // Updated list of badge IDs
    } catch (error) {
      return rejectWithValue("Failed to update player badges");
    }
  }
);
