import { BREAKPOINT } from "@/styles/constants";
import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;

  @media (max-width: ${BREAKPOINT.mobile}) {
    min-height: 300px;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 16px;
`;

export const Placeholder = styled.div`
  font-size: 18px;
`;
