import React from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { BackButtonProps } from "./types";
import { Button, Text } from "./styles";

export const BackButton = React.memo(
  ({ onClick }: BackButtonProps): JSX.Element => {
    return (
      <Button onClick={onClick}>
        <BiLeftArrowAlt size={20} />
        <Text>BACK</Text>
      </Button>
    );
  }
);
