import { useAppSelector } from "@/hooks/useAppSelector";
import { selectAuthState } from "@/redux/reducers/auth/reducer";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute = (): JSX.Element => {
  const { isAuthenticated, user, isLoading } = useAppSelector(selectAuthState);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated || user?.role !== "admin") {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
