import { activateAPI } from "@/api";
import { Badge } from "@/redux/models/BadgesDB";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAllBadges = createAsyncThunk(
  "badges/fetchBadges",
  async (_, { rejectWithValue }) => {
    try {
      const response = await activateAPI.get<Badge[]>("/api/badges");
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch badges");
    }
  }
);

export const addBadge = createAsyncThunk(
  "badges/addBadge",
  async ({ title, description }: Omit<Badge, "_id">, { rejectWithValue }) => {
    try {
      const response = await activateAPI.post("/api/badges", {
        title,
        description,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to add badge");
    }
  }
);

export const updateBadge = createAsyncThunk(
  "badges/updateBadge",
  async ({ _id, title, description }: Badge, { rejectWithValue }) => {
    try {
      const response = await activateAPI.put(`/api/badges/${_id}`, {
        title,
        description,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to update badge");
    }
  }
);

export const deleteBadge = createAsyncThunk(
  "badges/deleteBadge",
  async (id: string, { rejectWithValue }) => {
    try {
      await activateAPI.delete(`/api/badges/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue("Failed to delete badge");
    }
  }
);
