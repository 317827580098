import React from "react";
import { useNewRankHook } from "./hook";
import { Image, Text, Wrapper } from "./styles";
import { NewRankProps } from "./types";

export const NewRank = React.memo(
  ({ rank, size = "large" }: NewRankProps): JSX.Element => {
    const { rankImage } = useNewRankHook({ rank });

    return (
      <Wrapper>
        {rankImage ? (
          <>
            <Image $size={size} src={rankImage} alt="Rank" />
            <Text $size={size}>{rank}</Text>
          </>
        ) : (
          <div>{rank}</div>
        )}
      </Wrapper>
    );
  }
);
