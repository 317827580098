import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { LocationsState } from "./types";
import {
  addLocation,
  deleteLocation,
  fetchLocations,
  updateLocation,
} from "./thunk";
import { Location } from "../../models/LocationDB";
import { RootState } from "..";

const initialState: LocationsState = {
  error: null,
  isLoading: false,
  locations: [],
};

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchLocations.fulfilled,
        (state, action: PayloadAction<Location[]>) => {
          state.isLoading = false;
          state.locations = action.payload;
        }
      )
      .addCase(fetchLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(
        addLocation.fulfilled,
        (state, action: PayloadAction<Location>) => {
          state.locations.push(action.payload);
        }
      )
      .addCase(
        updateLocation.fulfilled,
        (state, action: PayloadAction<Location>) => {
          const index = state.locations.findIndex(
            (loc) => loc._id === action.payload._id
          );
          if (index !== -1) {
            state.locations[index] = action.payload;
          }
        }
      )
      .addCase(
        deleteLocation.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.locations = state.locations.filter(
            (loc) => loc._id !== action.payload
          );
        }
      );
  },
});

export const { reducer: locationsReducer } = locationsSlice;

export const selectLocationsState = (state: RootState) => state.locations;

export const selectAllLocationsSelector = createSelector(
  [selectLocationsState],
  (locationsState) => locationsState.locations
);
