import { useCallback, useMemo } from "react";
import { UseTileHookProps, UseTileHookResponse } from "./types";

export const useTileHook = ({
  content,
  onClick,
}: UseTileHookProps): UseTileHookResponse => {
  const memoizedContent = useMemo(() => content, [content]);
  const isClickable = useMemo(() => Boolean(onClick), [onClick]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return { memoizedContent, handleClick, isClickable };
};
