import { useMemo } from "react";
import {
  UseScoreDifferenceHookProps,
  UseScoreDifferenceHookResponse,
} from "./types";

export const useScoreDifferenceHook = ({
  score,
  topScore,
}: UseScoreDifferenceHookProps): UseScoreDifferenceHookResponse => {
  const differenceToRecord = useMemo(
    (): number => (topScore ? score - topScore : 0),
    [topScore, score]
  );

  return { differenceToRecord };
};
