import styled from "styled-components";

// Root container
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

// Overall Ranking section
export const OverallRanking = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
  }
`;

// Tables Grid
export const TablesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

// Individual Game Tables
export const GameTable = styled.div`
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

// Table Styles
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #eee;
  }
`;

// Table inside Overall Ranking
export const StyledOverallTable = styled(Table)`
  border-radius: 8px;
  overflow: hidden;

  th {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-weight: bold;
    padding: 10px;
  }

  td {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #f0f0f0;
  }

  tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.05);
  }
`;
