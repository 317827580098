import styled from "styled-components";

export const PersonalRecordCell = styled.td`
  color: lightgreen;
  font-weight: 600;
`;

export const TotalRow = styled.tr`
  font-weight: bold;

  th {
    text-align: center;
    font-size: 1rem;
  }

  td {
    text-align: right;
    font-size: 1rem;
    padding: 8px;
  }
`;
