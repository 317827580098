import styled from "styled-components";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FilledHeartIcon = styled(AiFillHeart)`
  color: red;
  font-size: 32px;
`;

export const EmptyHeartIcon = styled(AiOutlineHeart)`
  color: red;
  font-size: 32px;
`;
