import { BREAKPOINT } from "@/styles/constants";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;

  @media (max-width: ${BREAKPOINT.mobile}) {
    padding-top: 20px;
    min-height: auto;
  }
`;
