import { TotalScorePerGame } from "..";
import {
  getKeyIdForTopScore,
  getScoreByGame,
  isPersonalRecord,
} from "../../../../utils";
import { HARDCODED_COLUMNS } from "./constants";
import { CompareToYourselfTableProps } from "./types";
import { TableWrapper, Table, PersonalRecord } from "./styles";
import { useCompareToYourselfTableProps } from "./hook";
import React from "react";

export const CompareToYourselfTable = React.memo(
  ({ games }: CompareToYourselfTableProps): JSX.Element => {
    const { playerProfiles, topScores } = useCompareToYourselfTableProps();

    return (
      <>
        {games.map((game) => (
          <TableWrapper key={game.name}>
            <Table>
              <thead>
                <tr>
                  <th colSpan={HARDCODED_COLUMNS + playerProfiles.length}>
                    {game.name}
                  </th>
                </tr>
                <tr>
                  <th>Level</th>
                  {playerProfiles.map((p) => (
                    <th key={`${p.player.local_rank}-${p.player.player_name}`}>
                      {p.location.name}
                    </th>
                  ))}
                  <th>Top Score</th>
                </tr>
              </thead>
              <tbody>
                {game.levels.map((level) => {
                  const topScoreKey = getKeyIdForTopScore({
                    level_id: level.level_id,
                    game_id: level.game_id,
                  });

                  const hasPersonalRecord = playerProfiles.some(({ player }) =>
                    isPersonalRecord(
                      getScoreByGame(player, level.game_id, level.level_id),
                      topScores[topScoreKey]
                    )
                  );

                  return (
                    <tr key={`level-${level.id}`}>
                      <td>{level.level_id + 1}</td>
                      {playerProfiles.map(({ player }) => {
                        const score = getScoreByGame(
                          player,
                          level.game_id,
                          level.level_id
                        );

                        return (
                          <PersonalRecord
                            key={`${player.player_name}-${level.level_id}`}
                            $highlight={isPersonalRecord(
                              score,
                              topScores[topScoreKey]
                            )}
                          >
                            {score}
                          </PersonalRecord>
                        );
                      })}
                      <PersonalRecord $highlight={hasPersonalRecord}>
                        {topScores[topScoreKey] || 0}
                      </PersonalRecord>
                    </tr>
                  );
                })}

                <TotalScorePerGame
                  levels={game.levels}
                  players={playerProfiles.map((pp) => pp.player)}
                  forceShow
                  topScoresAllLocations={topScores}
                />
              </tbody>
            </Table>
          </TableWrapper>
        ))}
      </>
    );
  }
);
