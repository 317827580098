import { useAppDispatch } from "@/hooks/useAppDispatch";
import { useAppSelector } from "@/hooks/useAppSelector";
import { selectPlayerLinksState } from "@/redux/reducers/playerLinks/reducer";
import { fetchPlayerLinks } from "@/redux/reducers/playerLinks/thunk";
import { useEffect } from "react";
import {
  ApprovedPlayerLinksTable,
  DeniedPlayerLinksTable,
  PendingPlayerLinksTable,
} from "./components";
import { PageTitle } from "./styles";

export const PlayerLinks = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isLoading, error } = useAppSelector(selectPlayerLinksState);

  useEffect(() => {
    dispatch(fetchPlayerLinks());
  }, [dispatch]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <PageTitle>Player Links</PageTitle>
      <PendingPlayerLinksTable />
      <ApprovedPlayerLinksTable />
      <DeniedPlayerLinksTable />
    </div>
  );
};
