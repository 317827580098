export const maskEmail = (email?: string): string => {
  if (!email) {
    return "";
  }

  const [localPart, domain] = email.split("@");
  if (localPart.length <= 3) return `${localPart[0]}***@${domain}`;

  return `${localPart.slice(0, 2)}***${localPart.slice(-1)}@${domain}`;
};
