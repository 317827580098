import { MAX_LIVES } from "./constants";
import { LivesProps } from "./types";
import { Wrapper } from "./styles";
import { useLivesHook } from "./hook";

export const Lives = ({
  maxLives = MAX_LIVES,
  lives,
}: LivesProps): JSX.Element => {
  const { renderEmptyHearts, renderFilledHearts } = useLivesHook({
    lives,
    maxLives,
  });

  return (
    <Wrapper>
      {renderFilledHearts}
      {renderEmptyHearts}
    </Wrapper>
  );
};
