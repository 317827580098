import { useAppDispatch } from "@/hooks/useAppDispatch";
import { useAppSelector } from "@/hooks/useAppSelector";
import { selectAuthState } from "@/redux/reducers/auth/reducer";
import { selectBadgesState } from "@/redux/reducers/badges/reducer";
import { selectPlayerBadges } from "@/redux/reducers/playerBadges/reducer";
import {
  fetchPlayerBadges,
  updatePlayerBadges,
} from "@/redux/reducers/playerBadges/thunk";
import { useEffect, useMemo, useState } from "react";
import {
  BadgeTable,
  SaveButton,
  TableCell,
  TableHeader,
  TableRow,
} from "./styles";
import { PlayerBadgesProps } from "./types";

export const PlayerBadges = ({
  playerName,
}: PlayerBadgesProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { badges } = useAppSelector(selectBadgesState);
  const { playerBadges, isLoading, error } = useAppSelector(selectPlayerBadges);
  const { user } = useAppSelector(selectAuthState);
  const [selectedBadges, setSelectedBadges] = useState<string[]>([]);

  useEffect(() => {
    setSelectedBadges(playerBadges.map(({ _id }) => _id));
  }, [playerBadges]);

  useEffect(() => {
    if (playerName) {
      dispatch(fetchPlayerBadges({ playerName }));
    }
  }, [dispatch, playerName]);

  const canEdit = useMemo(
    () => playerName === user?.playerName,
    [playerName, user]
  );

  const onBadgeClick = (badgeId: string): void => {
    if (!canEdit) return; // Prevent editing if not the owner

    setSelectedBadges(
      (prevSelected) =>
        prevSelected.includes(badgeId)
          ? prevSelected.filter((id) => id !== badgeId) // Remove if already selected
          : [...prevSelected, badgeId] // Add if not selected
    );
  };

  const onSaveClick = (): void => {
    dispatch(updatePlayerBadges(selectedBadges));
  };

  const hasModifiedBadges = useMemo(() => {
    if (!selectedBadges || !playerBadges) {
      return false;
    }

    return (
      selectedBadges.sort().toString() !==
      playerBadges
        .map(({ _id }) => _id)
        .sort()
        .toString()
    );
  }, [selectedBadges, playerBadges]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <BadgeTable>
        <thead>
          <TableRow>
            <TableHeader>Title</TableHeader>
            <TableHeader>Description</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {badges.map((badge) => (
            <TableRow
              key={badge._id}
              $highlight={selectedBadges.includes(badge._id)}
              $clickable={canEdit}
              onClick={() => onBadgeClick(badge._id)}
            >
              <TableCell>{badge.title}</TableCell>
              <TableCell>{badge.description}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </BadgeTable>
      {canEdit && hasModifiedBadges && (
        <SaveButton onClick={onSaveClick}>Save Changes</SaveButton>
      )}
    </div>
  );
};
