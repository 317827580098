import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BadgeState } from "./types";
import { Badge } from "../../responseTypes";

const initialState: BadgeState = {
  isLoading: false,
  badges: [],
};

const SORT = {
  ASC: [1, -1],
  DESC: [-1, 1],
};

const badgeSlice = createSlice({
  name: "badge",
  initialState,
  reducers: {
    fetchBadgesLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    fetchBadgesComplete: (state, action: PayloadAction<Badge[]>) => {
      state.badges = action.payload;
    },
    sortBadgesByTitle: (state) => {
      switch (state.sortByTitles) {
        case "ASC":
          state.sortByTitles = "DESC";
          break;
        case "DESC":
          state.sortByTitles = "ASC";
          break;
        default:
          state.sortByTitles = "ASC";
          state.sortByStars = undefined;

          break;
      }

      const [sortATitle, sortBTitle] = SORT[state.sortByTitles];
      state.badges = state.badges.sort((a, b) =>
        a.title > b.title ? sortATitle : sortBTitle
      );
    },
    sortBadgesByStars: (state) => {
      switch (state.sortByStars) {
        case "ASC":
          state.sortByStars = "DESC";
          break;
        case "DESC":
          state.sortByStars = "ASC";
          break;
        default:
          state.sortByStars = "ASC";
          state.sortByTitles = undefined;

          break;
      }
      const [sortAStars, sortBStars] = SORT[state.sortByStars];
      state.badges = state.badges.sort((a, b) =>
        a.stars > b.stars ? sortAStars : sortBStars
      );
    },
  },
});

export const {
  reducer: badgeReducer,
  actions: {
    fetchBadgesComplete,
    fetchBadgesLoading,
    sortBadgesByStars,
    sortBadgesByTitle,
  },
} = badgeSlice;
